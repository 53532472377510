
function AddsButtion({onClick, name}) {
  return (
    
     <>
     <button 
      className=' bg-white px-[14px] py-[8px] border-[#D9D9D9] hover:border-[#7210EE] hover:text-[#7210EE] border-2 rounded-[99px] text-[#160404]'
      onClick={onClick}
     >{name} </button>   
    </>
    
  )
}

export default AddsButtion