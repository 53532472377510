import React from 'react'
import Face from '../Face'
import Sound from '../../Sounds/B6.mp3'
function Compitition() {
  return (
    <div className=' px-7 sm:mx-10 lg:mx-20 bg-task-bg grid sm:grid-cols-12 gap-4 lg:py-[96px] sm:py-[48px] pt-[32px] pb-[48px]'>
    <div className=' sm:col-span-4'>
    <div className=' sm:flex sm:justify-center'>
    <div id='study'>
    <Face
      Sound={Sound}
      prevLink='#study'
      nextLink='#map'
    />
    </div>
    </div>
    
</div>
<div className=' sm:col-span-6'>
   <h1 className='text-[26px] lg:text-[39px] font-[600] sm:w-[80%] text-[#160404] pb-3'>Competitor study</h1>
   <p className='text-[16px] font-[400] sm:w-[80%] text-[#333333] pb-3'> 
   We studied a couple of legacy crowdfunding platforms like Indiegogo, kickstarter and gofundme, to identify usability patterns, communication style, brand personality and general opportunities.
   <br></br><br></br>
   <b>The learning:</b> Their user experience is largely the same and offers users very little differentiation.</p>
   <h1 className='text-[22px] lg:text-[32px] font-[600] sm:w-[80%] text-[#160404] pt-5'>Other stuff users like</h1>
   <p className='text-[16px] font-[400] sm:w-[80%] text-[#333333] pb-3'> 
   We also looked for engaging UX design patterns that appealed to younger users. Our search led us to the mobile dating app industry and the likes of Tinder, and Bumble.
   <br></br><br></br>
   <b>The learning:</b>Swipe interactivity is appealing to younger users.</p>
</div>
</div>
  )
}

export default Compitition