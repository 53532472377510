import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
function Navbar() {
  const [open, setOpen] = useState(false)
  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };
  return (
    <div className='  fixed bg-[#1D1B21] z-50  px-5 sm:px-10 lg:px-10  w-full '>
    <div className='  p-3 flex  border-b border-b-[#7E7986] justify-between'>
     <div className=' flex border relative  rounded-[18px]'>
     <NavLink
        className={({isActive}) =>{
            return( ' text-[#313030] p-1.5  pl-6 text-start  pr-5 ' + (
         !isActive ? ' hover:text-[#A157FF] text-center bg-[#D9D9D9] rounded-l-[18px]' : ' px-2 bg-white -mr-4 text-center z-50 text-[#A157FF] border border-[#C4C4C4] rounded-[18px]'
         ))
          }} 
         to='/art'>
          Art
    </NavLink>
    <NavLink 
        className={({isActive}) =>{
        return( ' text-[#313030] p-1.5 pl-6 text-start  pr-5 ' + (
         !isActive ? ' hover:text-[#A157FF] text-center bg-[#D9D9D9] rounded-r-[18px]' : ' px-2 bg-white -mr-4 text-center text-[#A157FF] border border-[#C4C4C4] rounded-[18px]'
         ))
          }} 
         to='/'>
          Design
    </NavLink>
     </div> 



     <div className=' block md:hidden'>
     <div
                          className="relative inline-block"
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                        <button className=" text-[#9E99A5]  font-[500px] text-[14px] flex px-4 py-2 rounded">
                        Menu  {open ? <KeyboardArrowDownIcon/> :<KeyboardArrowUpIcon/>}
                          </button>
                          
                          {open && 
                            <div className="absolute xl:w-[140px] left-0 top-full bg-[#3F3A48] border  p-4 shadow-md">
                            <a href='#collection' className=' text-[#9E99A5] text-[14px] hover:bg-[#110F14]' >Collections</a>
                              {/* <a href='#exhibition' className='text-[#9E99A5] text-[14px]  hidden md:block'>Exhibitions</a> */}
                              <a href='#bio' className=' text-[#9E99A5] block text-[14px] hover:bg-[#110F14]'> Bio</a>
                              <a href='#contact' className=' text-[#9E99A5] hover:bg-[#110F14] text-[14px] '>Contact me</a>
                            </div>
                          }
      </div>
     </div>
     <div className=' md:flex gap-6 hidden  pt-2 justify-between'>
          <a href='#collection' className=' hover:text-[#A157FF] text-[14px] text-[#9E99A5]' >Collections</a>
          {/* <a href='#exhibition' className='hover:text-[#A157FF] text-[14px] text-[#9E99A5] hidden md:block'>Exhibitions</a> */}
          <a href='#bio' className=' hover:text-[#A157FF]  text-[14px] text-[#9E99A5] hidden md:block'> Bio</a>
          <a href='#contact' className=' hover:text-[#A157FF] text-[14px] text-[#9E99A5] hidden md:block'>Contact me</a>
     </div>  
     <div className=' flex gap-6 pt-2'>
      <Link to={'/CV'} className=' hidden md:block hover:text-[#A157FF] text-[#9E99A5] text-[14px]'>CV</Link>
      <Link to={'/dcentralizm'} className=' text-[#9E99A5] hover:text-[#A157FF] text-[14px]'>Dcentralizm</Link>
     </div>
     </div>
    </div>
  )
}

export default Navbar