import React from 'react'
import Face from '../Face'
import Loan1 from '../../images/dd1.png'
import Loan2 from '../../images/dd2.png'
import ND from '../../images/nd2.png'
import PD from '../../images/pd2.png'
import mediumZoom from 'medium-zoom'
import MagnifiedImage from '../MagnifiedImage'
import Sound from '../../Sounds/c5.mp3'
function LoanCal() {
    const zoom = React.useRef(mediumZoom())
  return (
    <div className=' md:mx-10 px-5 py-[48px] sm:py-[64px] lg:py-[96px] sm:mx-20 grid sm:grid-cols-12 '>
         <div className=' sm:col-span-4 '>
        <div className=' sm:flex sm:justify-center'>
        <div id='loan'>
        <Face
            Sound={Sound}
            prevLink='#return'
            nextLink='#easy'
        />
        </div>
        </div>
          
        </div>
        <div className=' sm:col-span-6'>
        <div>
        <h1 className='text-[26px]  lg:text-[39px] mb-2 font-[600] text-[#160404]'>Loan calculator</h1>
            <div className=' mb-[48px] grid md:grid-cols-2 rounded border border-[#C0C0C0]'>
                <div>
                    <h1 className=' font-[600] text-[16px] text-[#160404] border-b border-b-[#C0C0C0] bg-[#F1EFF5] p-5'>Research findings</h1>
                    <div className=' p-5 py-7'>
                        <p className=' pb-4'>Participants repeatedly asked for information about the interest rates while interacting with the input field.</p>
                       
                    </div>
                </div>
                <div>
                    <h1 className=' font-[600] text-[16px] text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Design decisions</h1>
                    <div className=' p-5 py-7'>
                        <p className=' pb-4'>Added a tool tip. See “new design” below the table.</p>
                        <img src={Loan1} alt='return'/>
                    </div>
                </div>
                <div className=' border-t border-t-[#C0C0C0]'>
                <h1 className=' font-[600] md:hidden text-[16px] text-[#160404] border-b border-b-[#C0C0C0] bg-[#F1EFF5] p-5'>Research findings</h1>
                <div className=' p-5 py-7'>
                
                        <p className=' pb-4'>Participants seemed uneasy with the intense blue border at the corner of the tool. See “previous design” below the table.</p>
                       
                    </div> 
                </div>
                <div>
                <h1 className=' font-[600] md:hidden text-[16px] text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Design decisions</h1>
                <div className=' p-5 py-7 border-t border-t-[#C0C0C0]'>
                        <p className=' pb-4'>Removed the blue border and added soft shadow to the tool</p>
                        <img src={Loan2} alt='return'/>
                    </div>
                </div>
                
            </div>
            <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>New design</h1>
            <div className='mb-[48px]'>
            <MagnifiedImage
                  src={ND}
                    alt="New design"
                    zoom={zoom.current}
                    background="white"
            />
            </div>
            <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>Previous design</h1>
            <div className='mb-[48px]'>
            <MagnifiedImage
                  src={PD}
                    alt="Previous design"
                    zoom={zoom.current}
                    background="white"
            />
             
            </div>
        </div>
        </div>
    </div>
  )
}

export default LoanCal