



import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewCom from './ReviewCom';
import P1 from '../../images/r1.png'
import P2 from '../../images/r3.png'
import P3 from '../../images/r4.png'
import P4 from '../../images/r2.png'
import P5 from '../../images/r5.png'
import P6 from '../../images/r6.png'
import P7 from '../../images/r7.png'
import P8 from '../../images/r8.png'
import P9 from '../../images/r9.png'
import Back from '../../images/forward.png'
import Progress from "../SelectValue/Progress";

function SampleNextArrow(props) {
  return (
    <div 
    />
  );
}

function SamplePrevArrow(props) {
  return (
    <div
    />
  );
}

export default class PreviousNextMethods extends Component {

    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }
    state = {
      slideIndex: 0,
      updateCount: 0
    };
    render() {
      const settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        cssEase: "linear",
        autoplay: false,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
             
            }
          },
          {
            breakpoint: 950,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
             
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            }
          }
        ],
        afterChange: () =>
        this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    
      };
        let count = this.state.slideIndex / 8
        let finalCount = count * 100
      return (
        <div className="" id="review">
        <div className=" md:mt-10 mx-5  flex mb-6 mt-4 justify-between">
      <h1 className=" text-[23px] mb-3 sm:pl-5 md:pl-0 sm:text-[26px] lg:text-[32px] font-[600] text-back-color2 ">Adplist.org reviews</h1>
     
      </div>
          <Slider ref={c => (this.slider = c)} {...settings}>
            <div key={1}>
            <ReviewCom
                img={P1}
                body='Aimua is super helpful, honest and encouraging. He gave me very useful advice about how to approach case studies, my career history and my general prospects. Very much looking forward to reviewing my case study with him.'
                name='Jordan Fisher 🇨🇦'
                sec='UX designer at jordanfisher.co'
            />
            </div>
            <div key={2}>
            <ReviewCom
                img={P2}
                body="He provided me with actionable tips and advice that I can use to strengthen my portfolio. He also shared advice with me about how to share my story so that people can understand me better. Aimua was encouraging, honest, and accommodating."
                name='Sara Denise 🇺🇸'
                sec="Sales Associate at Trader Joe's"
            />
            </div>
            <div key={3}>
            <ReviewCom
                img={P3}
                body="Encouraging and great feedback! We actually went overtime with our session which was really helpful. If you need feedback or advice on case studies you're working on, I would definitely recommend booking a session with Aimua."
                name='Ashley Yien 🇨🇦'
                sec="Junior Product Designer at Simon Fraser University"
            />
            </div>
            <div key={4}>
            <ReviewCom
                img={P4}
                body="Aimua is very patient, informative and eager to help. I enjoyed my first chat with him as it helped me gain perspective on my UX journey and I'll definitely book another session with him to review my case study."
                name='Aslan Amini 🇨🇦'
                sec="UX/ Product Designer at coffeetext.ca/UBC"
            />
            </div>
            <div key={5}>
            <ReviewCom
                img={P5}
                body="Aimua is the definition of mentorship. He is very empathetic... He carefully listened to all I had to say. I think he is just the right solution for any new product designer. I can't wait to have my next session with him. Thank you so much Aimua."
                name='Segun Awodeji 🇳🇬'
                sec="Product Designer at Rapid Solutions"
            />
            </div>
            <div key={6}>
            <ReviewCom
                img={P6}
                body="It was a pleasure to meet Aimua. He is super helpful, positive and encouraging. I enjoyed my session with him."
                name='Moona Nademian 🇨🇦'
                sec='UI/ UX Designer at Campus Support'
            />
            </div>
            <div key={7}>
            <ReviewCom
                img={P7}
                body='I had a great session talking about how to accept rejections and be motivated, keep iterating and exploring various opportunities in the Industry. Also got some good insights for my portfolio. It was my pleasure to meet Aimua.'
                name='Aditya Chintha 🇮🇳'
                sec="User Experience Designer II at HealthEdge"
            />
            </div>
            <div key={8}>
            <ReviewCom
                img={P8}
                body="He gave great advice. Highly recommend this mentor."
                name='Mohamed Shirwa 🇺🇸'
                sec='Student at Georgia State University'
            />
            </div>
            <div key={9}>
          <ReviewCom
                img={P9}
                body='I had a great session with Aimua, he is very resourceful and a good listener. He extended our time to go through my portfolio. I will definitely be booking another session with him.'
                name='Felix Owolabi 🇿🇦'
                sec="UX/UI Designer, EOH"
            />
            
          </div>
          </Slider>
        <div className=" flex p-3 justify-between">
        <Progress per={finalCount}/>
        <div>
            <button className="px-2" onClick={this.next}>
            <img src={Back} className=' ' alt='Back'/>
          </button>
          
        </div> 
        </div>
        </div>
      );
    }
  }
