import React from 'react'
import Face from '../Face'
import Sound from '../../Sounds/c2.mp3'
function Task() {
  return (
    <div className='mx-5 sm:mx-10  bg-task-bg  lg:mx-20 rounded px-6  py-[48px] sm:py-[64px] lg:py-[96px] flex flex-col justify-center'>
        <div className=' grid sm:grid-cols-12 gap-5'>
        <div className=' sm:col-span-4 '>
        <div className=' sm:flex sm:justify-center'>
        <div id='task'>
        <Face
            Sound={Sound}
            nextLink='#step'
            prevLink='#intro'
        />
        </div>
        </div>
          
        </div>
            <div className=' sm:col-span-8'>
                <h1 className='  lg:text-[39px] mb-2 font-[600] text-[#160404]'>My task</h1>
                <div>
                    <ul>
                        <li>&#x2022; Use key research fondings to redesign MyMoney Loan landing page.  </li>
                        <li>&#x2022; Apply the new Canada Post Rebrand design system to the page.</li>
                        <li>&#x2022; Design blog article pages for the financial literacy section.</li>
                        <li>&#x2022; Design a credit score checker tool.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Task