import React from 'react'
import Face from '../Face';
import Sound from '../../Sounds/c1.mp3'
function SecondSection() {
  return (
    <div className=' sm:mx-10 mx-5 pt-[48px] sm:pt-[64px] lg:pt-[96px] lg:mx-20'>
   
        <div className='  grid sm:grid-cols-12 gap-6'>
        <div className=' sm:col-span-4 '>
        <div className=' sm:flex sm:justify-center'>
        <div id='intro'>
        <Face 
          Sound={Sound}
          nextLink='#task'
          prevLink='#intro'
        />
        </div>
        </div>
          
        </div>
            <div className=' sm:col-span-8'>
                <p className=' sm:text-[20px] text-[#333333] font-[600] lg:w-[80%]'>Canada Post had recently partnered with TD Bank to deliver a simple and affordable personal loan product targeting underserved people.</p>
               
            </div>
        </div>
       
      
    </div>
  )
}

export default SecondSection