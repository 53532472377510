import React from 'react'

import I1 from '../../images/fs1.jpg'
import I2 from '../../images/fs2.png'
import ReuseableCard from '../Decentralized/ReuseableCard'
import ReuseableCar from './ReusableCard2'
function Content() {
  return (
    <div>
        <ReuseableCar
         image={I1}
            title={'Not for sale'}
            media={"Paint markers, strung plastic beads, aged steel nuts, and Plastic(nylon) canvas."}
            content={<p>
                In 2018 I discovered that thousands of women from my ancestral city, Benin City, Nigeria, are frequently lured into a treacherous journey through the Sahara Desert and across the Mediterranean Sea to Europe and a life of sex slavery. During these journeys, it is common for these girls to experience extreme abuse to their bodies and minds. Meeting people who have gone through these experiences inspires me to create art that addresses the subject matter.
                <br></br><br></br>
                By visually contrasting "cartoonish" figures with more rigid materials like steel, nuts, and plastic beads on the malleable material of a plastic nylon canvas, I aim to question the perception of the viewers on how they interpret form and the female body. The red plastic beads are objects of beauty and opulence amongst the people of my ancestral culture, the Edo people; they represent the enchantment of money as a catalyst for the issues of hyper-sexualization of women’s bodies.
                <br></br><br></br>
                Meanwhile, I explicitly state my objection to the commoditization of the body in a short statement written in both English and simplified Chinese characters, pointing to the regions with the most sex-trafficking victims in the world.
            </p>}
            linkText={"View on Sachiart.com"}
            link={"http://www.saatchiart.com/aimuan"}
        />
        <ReuseableCard
            image={I2}
            title={'Untitled'}
            media={"Paint markers, strung plastic beads, aged steel nuts, and Plastic(nylon) canvas."}
            content={"This is the first piece created in the series. It offered the opportunity to explore and create a new visual language around the theme of sexual exploitation."}
            linkText={""}
        />
    </div>
  )
}

export default Content