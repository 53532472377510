import React from 'react'
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import BB from '../../images/sum.png'
import BS from '../../images/sums.png'
import Content from './Content';
import Contactus from '../art/Contact';
function Main() {
  return (
    <div className= ' mx-5 sm:mx-20 sm:pt-5  h-full'>
        
    <div className='sm:fixed px-5 sm:top-[6%] pt-5  sm:mt-12 sm:left-0 '>
    <div className= ' sm:flex sm:w-1/2' >
        <div className=' sm:px-20'>
        <div className=' flex md:block pt-5 sm:pt-0 justify-between sm:justify-center '>
        <div className=' pt-20 sm:pt-0'>
      
        <div className=' flex sm:block gap-4'>
        <div>
        <img src={BB}  className='mx-auto hidden sm:block' alt='head'/> 
        <img src={BS}  className='mx-auto sm:hidden block' alt='head'/> 
        </div>
        <div className=' flex sm:hidden gap-4'>
        <div className=' '>
                 <a href='https://www.mississauga.ca/arts-and-culture/arts/public-art/temporary-public-art/summer-connections/'>  <p className=' font-semibold text-[#A157FF] underline  text-[16px]'>View on Mississauga city site  <LaunchTwoToneIcon className='text-[#A157FF]  '/></p></a> 
                   
                </div>
            </div>
        </div>
          
           <div className=' pb-4 sm:flex xl:justify-center'>
           <div>
           <h1 className=' text-[#BAB3C3] mt-7 lg:text-center text-3xl font-medium mb-4 leading-10'>Summer connection</h1>
           <p className=' text-[#7E7986] font-normal lg:text-center text-[16px]   '>In this six-piece banner series, Ai asks viewers to question their ideas about their connection with community. The artwork explores collective stories of diverse people bound together by their sense of community, duty, passion, and culture.</p>
           </div>
          
           </div>
            <div className=' wow animate__animated animate__zoomInUp flex pt-3  justify-center'>
            <div className=' hidden sm:flex gap-4'>
            <div className=' flex gap-4'>
            <a href='https://www.mississauga.ca/arts-and-culture/arts/public-art/temporary-public-art/summer-connections/'>  <p className=' font-semibold text-[#A157FF] underline  text-[16px]'>View on Mississauga city site  <LaunchTwoToneIcon className='text-[#A157FF]  '/></p></a> 

                </div>
            </div>
                
            </div>
            </div>
            </div>
           
        </div>
        </div>
    </div>
    <div className=' sm:grid sm:grid-cols-2  sm:gap-4 sm:pt-10 md:mx-5 sm:overflow-y-auto'>
    <div className='hidden sm:block'>

    </div>
      <div className= 'sm:mr-10 pt-5 sm:z-30' >
        <Content/>
        <Contactus/>
        <div className=' flex justify-end'>
        <p className=' text-[#7E7986]'>© Ai dezign</p>
        </div>
       
      </div>
        
    </div>
</div>
  )
}

export default Main