import React from 'react'
import Face from '../Face'
import R2 from '../../images/dd3.png'
import PD from '../../images/pd3.png'
import ND from '../../images/nd3.png'
import PN from '../../images/nnL.png'
import PNN from '../../images/nns.png'
import mediumZoom from 'medium-zoom'
import MagnifiedImage from '../MagnifiedImage'
import Sound from '../../Sounds/c6.mp3'
function EasySteps() {
    const zoom = React.useRef(mediumZoom())
  return (
    <div className=' bg-task-bg  mx-5 px-5 md:mx-10  py-[48px] sm:py-[64px] lg:py-[96px] lg:mx-20 grid sm:grid-cols-12 '>
    <div className=' sm:col-span-4 '>
        <div className=' sm:flex sm:justify-center'>
        <div id='easy'>
        <Face
            Sound={Sound}
            prevLink='#loan'
            nextLink='#score'
        />
        </div>
        </div>
          
        </div>
    <div className=' sm:col-span-6'>
    <h1 className=' text-[26px] lg:text-[39px] mb-2 font-[600] sm:pt-0 pt-5 text-[#160404]'>“Easy steps” content block</h1>
        <div className=' mb-[48px] grid sm:grid-cols-2 rounded border border-[#C0C0C0]'>
            <div>
                <h1 className=' font-[600] text-[16px] text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Research findings</h1>
                <div className=' p-5 py-7'>
                    <p className=' pb-4'>A popular feedback was that the “easy steps” content was too much to read.</p>
                   
                </div>
            </div>
            <div>
                <h1 className=' font-[600] text-[16px] text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Design decisions</h1>
                <div className=' p-5 py-7'>
                    <p className=' pb-4'>Reduction of the number of steps from 4 to 3. See “new design” below the table.</p>
                    <img src={R2} alt='return'/>
                </div>
            </div>
            <div className=' border-t border-t-[#C0C0C0]'>
            <h1 className=' font-[600] text-[16px] sm:hidden text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Research findings</h1>
                <div className=' p-5 py-7'>
                        <p className=' pb-4'>Participants also thought that the content block was too far down the page.</p>
                       
                    </div> 
                </div>
                <div>
                <h1 className=' font-[600] text-[16px] sm:hidden text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Design decisions</h1>
                <div className=' p-5 py-7 border-t border-t-[#C0C0C0]'>
                        <p className=' pb-4'>Moved the content block 3 places up the page. See “new design” below the table.</p>
                        
                    </div>
                </div>
               
        </div>
        <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>New design</h1>
        <div className='mb-[48px]'>
        <MagnifiedImage
                  src={ND}
                    alt="New design"
                    zoom={zoom.current}
                    background="white"
            />
        </div>
        <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>Previous design</h1>
        <div className='mb-[48px]'>
        <MagnifiedImage
                  src={PD}
                    alt="Previous design"
                    zoom={zoom.current}
                    background="white"
            />
          
        </div>
        <div className=' pb-[96px]'>
            {/* <div className=' flex mb-2 lg:justify-between'>
                <p className=' lg:text-[16px] mb-2 font-[600] text-[#333333]'>New design</p>
                <p className=' lg:text-[16px] mb-2 font-[600] text-[#333333]'>Previous design</p>
            </div> */}
            <div>
            <img src={PN} className=' hidden sm:block' alt='newd'/> 
            <img src={PNN} className=' sm:hidden ' alt='newd'/> 
            </div>
        </div>
    </div>
</div>
  )
}

export default EasySteps