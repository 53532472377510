import React from 'react'
import Face from '../Face'
import Sound from '../../Sounds/B12.mp3'
function Conclusion() {
  return (
    <div className=' px-7 sm:mx-10 lg:mx-20 bg-task-bg grid sm:grid-cols-12 gap-4 lg:py-[96px]  sm:py-[48px] pt-[32px] pb-[48px]'>
     <div className=' sm:col-span-4'>
        <div className=' sm:flex sm:justify-center'>
        <div id='conclusion'>
            <Face
              Sound={Sound}
              prevLink='#test'
              nextLink='##'
            />
        </div>
        </div>
      </div>
<div className=' sm:col-span-6'>
   <h1 className='text-[26px] lg:text-[39px] font-[600] sm:w-[80%] text-[#160404] pb-3'>Conclusion</h1>
   <p  className='text-[25px]  font-[600]  text-[#160404] py-3'>The good</p>
   <div>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; It felt great smashing the deadline.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; We learnt a lot about the gamification features.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; The task completion rate is pretty high.</p>
     </div>
     <p  className='text-[25px]  font-[600]  text-[#160404] py-3'>The not so good</p>
   <div>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; The “pledge” payment CTA is a bit confusing.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; Some users did not use the swipe feature intuitively.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022;  Young adults do not earn much, so they do not seem willing to part ways with their money.</p>
     </div>
     <p  className='text-[25px]  font-[600]  text-[#160404] py-3'>Next steps</p>
   <div>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; Explore other target user groups with higher funding motivation e.g corporations with CSR obligations.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022;Make iterations based on the findings and test them.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; Do more research on subscription giving.</p>
     </div>
</div>
</div>
  )
}

export default Conclusion