import React from 'react'

function ReviewCom({body, img, name, sec}) {
  return (
    <div className=' mx-2 p-4 bg-[#EFF0F2] rounded'>
        <p className=' text-[#160404] text-[16px] font-[400] lg:h-[330px] h-[240px] md:h-[490px] pb-5'>{body}</p>
        <div className=' flex md:h-[100px] xl:h-[60px] gap-4'>
            <div>
                <img src={img} alt='img'/>
            </div>
            <div className=' '>
                <p className=' pb-2'>{name}</p>
                <p className=' text-[10px]'>{sec}</p>
            </div>
        </div>
    </div>
  )
}

export default ReviewCom