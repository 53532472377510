



import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import P1 from '../../images/sk41.png'
import P2 from '../../images/sk42.png'
import P3 from '../../images/sk43.png'
import P4 from '../../images/sk44.png'
import P5 from '../../images/sk45.png'
import P6 from '../../images/sk46.png'
import Back from '../../images/forward.png'
import Progress from "../SelectValue/Progress";

function SampleNextArrow(props) {
  return (
    <div 
    />
  );
}

function SamplePrevArrow(props) {
  return (
    <div
    />
  );
}

export default class Sk4 extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }
    state = {
      slideIndex: 0,
      updateCount: 0
    };
    render() {
      const settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        cssEase: "linear",
        adaptiveHeight: true,
        autoplay: false,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
             
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            }
          }
        ],
        afterChange: () =>
        this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
      };
      let count = this.state.slideIndex / 5
      let finalCount = count * 100
      return (
        <div className="py-[48px] ">
        <div className=" md:mt-10 mx-5  flex mb-6 mt-4 justify-between">
      <h1 className=" text-[16px] mb-3  font-[600] text-back-color2 ">KYC flow</h1>
     
      </div>
          <Slider ref={c => (this.slider = c)} {...settings}>
            <div key={1}>
                <img src={P1} alt='Sketch'/>
            </div>
            <div key={2}>
            <img src={P2} alt='Sketch'/>
            </div>
            <div key={3}>
            <img src={P3} alt='Sketch'/>
            </div>
            <div key={4}>
            <img src={P4} alt='Sketch'/>
            </div>
            <div key={5}>
            <img src={P5} alt='Sketch'/>
            </div>
            <div key={6}>
            <img src={P6} alt='Sketch'/>
            </div>
          </Slider>
        <div className=" flex justify-between">
        <Progress per={finalCount}/>
        <div>
      
            <button className="px-2" onClick={this.next}>
            <img src={Back} className=' ' alt='Back'/>
          </button>
          
        </div> 
        </div>
        </div>
      );
    }
  }
