import React from 'react'
import D2 from '../../images/d2.png'
import D3 from '../../images/d3.png'
import D4 from '../../images/d4.png'
import D5 from '../../images/d5.png'
import D6 from '../../images/d6.png'
import D7 from '../../images/d7.png'
import D8 from '../../images/d8.png'

import ReuseableCard from './ReuseableCard'
function Content() {
  return (
    <div>
          <ReuseableCard
            title={'One'}
            header={'Physical'}
            image={'https://s3-alpha-sig.figma.com/img/0ecd/4b44/75d7fa6698e0e29285ba8ba3bae557c5?Expires=1696809600&Signature=ecIfCt4-FTDz6X2LYMHlWRf6RMBxSE3vEcYqh672JOi7j9oRAsNY8mtPM9FkSLpvGCQYz4hOBLUn5u6SD9-T1081Ota1s56QNLFrU03YRRUPGctn~8Un4ZPQWBdUxrXsVYjuN~ZBTuVmeKxZwK93JICEIhwH7RfOXgWpzRBF9SaTHPNsTrNRRmwgqDHgNNI9XPZ8zUWFMT-aM1bueMUiTJ1DfKhIqA1h4H84Xc7x3Y2IytHysc6iUzjqRTgp-IREg05e297raCgGlDnZhXPdmPdpC2wDvwC77Pe1nYOI7AJTu3Qh2tHoVu3HNRmagGXl9jbBi8fUb9UPS~akw52s2Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4'}
            media={"Paint marker on cardboard, aged metal nuts and plastic beads."}
            linkText={"View on Sachiart.com"}
            link={'http://www.saatchiart.com/aimuan'}
            content={<p>
                     The art juxtaposes centralized and decentralized elements in a way that reflects the duality of human systems.
                        <br></br><br></br>
                    I was inspired to Upcycle one of the many cardboard boxes in my home, into a canvas for my art, symbolic of the transformation of systems.   
                    <br></br><br></br>
                    The colorful interconnected facial features capture the collective stories of diverse people bound together by their sense of community, duty, passion, and culture.
            </p>}   
        />
        <ReuseableCard
            title={'Content Warper'}
            header={'Digital'}
            image={D3}
            media={"Paint marker on cardboard, aged metal nuts and plastic beads."}
            linkText={""}
            content={<p>
                   An exciting collab with the fabulous <span className=' underline'>@nevergalready</span>, who animated The Content Warper to be a disjointed, exaggerated and polarized experience of reality that the search engines algorithms present us with. Misrepresenting our experiences, warping information by distorting and marginalizing some voices while amplifying others. Available on the artivive app.
            </p>}   
        />
        <ReuseableCard
            title={'Respect the peace'}
            header={''}
            image={D4}
            media={"Audiovisual digital art"}
            linkText={"View NFT"}
            link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/14787261214573115780979199272131482998992061162154467185933527706767324086282/'}
            content={<p>
                   “Respect The Peace” is a collaboration with a great brother <span className=' underline'>@opensea</span> and the 8th art piece from the <span className=' underline'>@artzisartsy</span>  NFT collection called U.N.I.T.Y.
            </p>}   
        />
         <ReuseableCard
            title={'Keeping Receipt$'}
            header={''}
            image={D5}
            media={"Audiovisual digital art"}
            link={'https://knownorigin.io/gallery/13658000-ai-dezign-x-artz-keeping-receipt-001'}
            linkText={"View NFT"}
            content={<p>
                 This artwork is an <span className=' underline'>#NFT</span> collaboration with the dope artist <span className=' underline'>@rejectdreams</span>  and music artiste Trinidad James. The artwork is based on a hip hop song created by both Trinidad and Artz.
            </p>}   
        />
         <ReuseableCard
            title={'Accordion'}
            header={''}
            image={D6}
            media={"Audiovisual digital art"}
            linkText={"Play on instagram"}
            link={'https://www.instagram.com/reel/Cai3bTEjbPm/?utm_source=ig_web_button_share_sheet&igshid=MzRlODBiNWFlZA=='}
            content={<p>
                This piece is a fusion of the amazing jazz hip-hop sounds of great <span className=' underline'> @abstractorchestra </span> , with the dcentralizm art form. 
                    <br></br>  <br></br>
                The art captures the creative connection that can manifest between people, regardless of race and culture.
            </p>}   
        />
         <ReuseableCard
            title={'Yo Méné Nou La'}
            header={''}
            image={D7}
            media={"Audiovisual digital art"}
            link={'https://www.instagram.com/reel/Cai3bTEjbPm/?utm_source=ig_web_button_share_sheet&igshid=MzRlODBiNWFlZA=='}
            linkText={"Play on instagram"}
            content={<p>
               This piece was an opportunity to create art and movement to <span className=' underline'>@c.lia.wa</span> amazing music. 

            </p>}   
        />
         <ReuseableCard
            title={'Covered'}
            header={''}
            image={D8}
            media={"Audiovisual digital art"}
            linkText={"Play on instagram"}
            link={'https://www.instagram.com/reel/Cf2uXCwD3YX/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='}
            content={<p>
              This piece is a collaboration with the amazing <span className=' underline'>@storytelah</span>, fusing Dcentralizm with the reflective and nostalgic hit single "my home" from his album "East railway line".
            </p>}   
        />
    </div>
  )
}

export default Content