import React from 'react'

function BasicCom({body}) {
  return (
    <div className='bg-[#E3E0EA] w-full p-5 grid'>
        <div className=' flex flex-col justify-center'>
            <p className=' text-center text-[14px] font-[400] text-[#160404]'>{body}</p>
        </div>
    </div>
  )
}

export default BasicCom