import React from 'react'
import Topsec from '../../Components/CanadaPostStudy/Topsec'
import Navbar from '../../Components/CanadaPostStudy/Navbar'
import SecondSection from '../../Components/CanadaPostStudy/SecondSection'
import Task from '../../Components/CanadaPostStudy/Task'
import Steps from '../../Components/CanadaPostStudy/Steps'
import Return from '../../Components/CanadaPostStudy/Return'
import LoanCal from '../../Components/CanadaPostStudy/LoanCal'
import EasySteps from '../../Components/CanadaPostStudy/EasySteps'
import CreditScore from '../../Components/CanadaPostStudy/CreditScore'
import Patnership from '../../Components/CanadaPostStudy/Patnership'
import Imagesource from '../../Components/CanadaPostStudy/Imagesource'
import FinancialLit from '../../Components/CanadaPostStudy/FinancialLit'
import Measurement from '../../Components/CanadaPostStudy/Measurement'
import Contactus from '../../Components/CanadaPostStudy/Contactus'
import Footer from '../../Components/Footer'
import Back from '../../images/ff.svg'
import Forward from '../../images/bb.svg'
import { Link } from 'react-router-dom'
import UsersSummary from '../../Components/CanadaPostStudy/UsersSummary'
import UserSum from '../../Components/CanadaPostStudy/UserSum'
import BasicJouney from '../../Components/CanadaPostStudy/BasicJouney'

function CPS() {
  return (
    <div className=' relative'>
    <diiv className='top-[520px] hidden sm:block fixed left-5'>
    <Link to='/boosty'><img src={Back} alt='Back'/></Link>  
      </diiv>
      <diiv className='top-[520px] hidden sm:block fixed right-5'>
      <Link to='/boosty'><img src={Forward} alt='Back'/></Link>
      </diiv>
        <Navbar/>
        <Topsec/>
        <SecondSection/>

        <UsersSummary/>
        <div className=' sm:hidden'>
          <UserSum/>
        </div>
        <BasicJouney/>
         <Task/>
        <Steps/>
       <Return/>
        <LoanCal/>
         <EasySteps/>
        <CreditScore/>
        <Patnership/>
        <FinancialLit/>
        <Imagesource/>
       
        <Measurement/>
        <Contactus/>
        <Footer/> 
    </div>
  )
}

export default CPS