import React from 'react'
import ReuseableCar from '../Forex/ReusableCard2'
import W1 from '../../images/wl1.png'
import W2 from '../../images/wl2.png'
function Content() {
  return (
    <div>
        <ReuseableCar
        image={W1}
        title={'Black Jesus'}
        media={"Acrylic on primed concrete wall"}
        linkText={""}
        content={<p>
            This project is a reimagining of biblical characters in the skin of black Africans and African culture. It was a bold statement at the time and was the first of its kind amongst christian institutions in West Africa. 
            <br></br> <br></br>
            This 70ft x 200ft mural was commissioned by St. Peter’s Catholic Church, Benin City, Nigeria. I worked as part of late Rev. Fr. Fernando Arizti’s team of 4 artists. 
        </p>}
        />
         <ReuseableCar
        image={W2}
        title={'Art for a cause 8.0'}
        media={"Acrylic, spray paint on primmed concrete wall"}
        content={<p>
           A community art project with “Army Primary School” kids in Lagos, Nigeria. It was organized by Socially Africa, First City Monument Bank(FCMB) and the Eko Tag. I and several creators were invited by lead artist “Osa Seven” to create murals within the school premises. Art materials were donated to the school.
        </p>}
        link={"https://www.bellanaija.com/2017/07/socially-africa-art-cause/"}
        linkText={"View publication"}
        />
    </div>
  )
}

export default Content