import React from 'react'
import CvCom from './CvCom'

function Content() {
  return (
    <div className=' bg-[#110F14] p-6 sm:p-16 mt-4 rounded'>
    <h1 className=' font-medium text-[32px] text-[#BAB3C3] mb-6'>Artist CV</h1>
    <div className=' mb-5'>
    <h1 className=' font-medium text-[16px] text-[#BAB3C3] mb-3'>Group Exhibition</h1>
    <CvCom
        year={"2021"}
        con={"NFT HypeBeast, Times Square, New York"}
    />
    <CvCom
        year={"2021"}
        con={"NFT.NYC. DorDor Gallery, Brooklyn, New York"}
    />
    <CvCom
        year={"2020"}
        con={"2020 Art Gala Showcase. Canarts Media, Toronto"}
    />
    <CvCom
        year={"2017"}
        con={"Art for a cause 8.0. (An art collaboration with Army Primary School kids), Lagos, Nigeria"}
    />
    <CvCom
        year={"2003"}
        con={"Nigerian Undergraduates’ Exhibition of Arts (organized for the Commonwealth Heads of Government Meeting). Games Village, Abuja, Nigeria"}
    />
    </div>
    <div className=' mb-5'>
    <h1 className=' font-medium text-[16px] text-[#BAB3C3] mb-3'>Solo Exhibitions</h1>
    <CvCom
        year={"2019"}
        con={"Time Doors. Mbari Uno House of Collaboration, Lagos, Nigeria "}
    />
    <CvCom
        year={"2004"}
        con={"Rhythm of Nature, Benin City, Nigeria"}
    />
    </div>
    <div className=' mb-5'>
    <h1 className=' font-medium text-[16px] text-[#BAB3C3] mb-3'>Collections</h1>
    <CvCom
        year={"2022"}
        con={"Summer connections - The city of Mississauga public art collection "}
    />
    <CvCom
        year={"2022"}
        con={"Dreaming in Ink, OpenSea (NFTs)"}
    />
     <CvCom
        year={"2021"}
        con={"A Thousand Lines, OpenSea (NFTs)"}
    />
    <CvCom
        year={"2019"}
        con={"First Bank of Nigeria 125th Anniversary painting collection, Lagos, Nigeria"}
    />
    </div>
    <div className=' mb-5'>
    <h1 className=' font-medium text-[16px] text-[#BAB3C3] mb-3'>Professional Experiences</h1>
    <CvCom
        year={"2023"}
        con={"Art selection committee member - Scholars Green Utility Box Wrap Project by The City of Mississauga "}
    />
    <CvCom
        year={"2022"}
        con={"Mississauga Art Banner Program commission "}
    />
     <CvCom
        year={"2019"}
        con={"Creative Design Mentor, Founder Institute, Lagos, Nigeria"}
    />
    <CvCom
        year={"2019"}
        con={"Senior artist, theartistconcept.org"}
    />
    <CvCom
        year={"2009"}
        con={"Judge, “Nokia Xpressmusic Top 5” Competition, Lagos, Nigeria"}
    />
    <CvCom
        year={"2006"}
        con={"“National Youth Corper” Service at the Nigerian Ministry of Arts & Culture, Abuja, Nigeria"}
    />
    <CvCom
        year={"2004"}
        con={"“Black Jesus mural commission - St. Peter’s Catholic Church, Benin City, Nigeria"}
    />
    </div>
    <div className=' mb-5'>
    <h1 className=' font-medium text-[16px] text-[#BAB3C3] mb-3'>Awards and Grants</h1>
    
    <CvCom
        year={"2020"}
        con={"TAC Newcomer Mentorship Recipient"}
    />
    <CvCom
        year={"2016"}
        con={"The Creative Chair 366 Award "}
    />
    </div>

    <div className=' mb-5'>
    <h1 className=' font-medium text-[16px] text-[#BAB3C3] mb-3'>Press and Publication</h1>
    <CvCom
        year={"2017"}
        con={"OVALAY No.2: The Illustrative Edition (Pt. 1), by D.OkuArt"}
    />
    <CvCom
        year={"2015"}
        con={"“Into The Light and Other Stories” by Olohi Ilevbare published on Amazon"}
    />
     <CvCom
        year={"2015"}
        con={"Interview conducted by Will Carey for Creative Chair, Behance (online)"}
    />
    </div>
    </div>
  )
}

export default Content