

import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Back from '../../images/forward.png'
import P1 from '../../images/ps1.png'
import P2 from '../../images/ps2.png'
import P3 from '../../images/ps3.png'
import P4 from '../../images/ps4.png'
import P5 from '../../images/ps5.png'
import P6 from '../../images/ps6.png'
import SliderCom from "./SliderCom";
import Progress from "../SelectValue/Progress";

function SampleNextArrow(props) {
  return (
    <div 
    />
  );
}

function SamplePrevArrow(props) {
  return (
    <div
    />
  );
}

export default class UserSum extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }
    state = {
      slideIndex: 0,
      updateCount: 0
    };
    render() {
      const settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        cssEase: "linear",
        autoplay: false,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
             
            }
          },
          {
            breakpoint: 950,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
             
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            }
          }
        ],
      
        afterChange: () =>
        this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    
      };
      let count = this.state.slideIndex / 5
        let finalCount = count * 100
      return (
        <div className="pb-[48px] mx-5 sm:pr-5 md:pb-[96px]">
        <div className=" md:mt-10 mx-5  flex mb-6 mt-4 justify-between">
      <h1 className=" text-[26px] mb-3 sm:pl-5 md:pl-0 md:text-[39px] font-[600] text-start text-back-color2 ">User persona summary</h1>
     
      </div>
          <Slider ref={c => (this.slider = c)} {...settings}>
            <div key={1}>
            <SliderCom
                img={P1}
                body='Self-sufficient, requires few financial products, mostly banks digitally, and conducts his own research.'
                name='Allan'
                title='Confident digital banker'
            />
            </div>
            <div key={2}>
            <SliderCom
                img={P2}
                body="Believe in supporting my local community, my finance does not stress me out. I value visiting branches and in-person interaction."
                name='Dom'
                title='Community-focused socializer'
            />
            </div>
            <div key={3}>
            <SliderCom
                img={P3}
                body='Stressed about my financial future, think big companies do not care. I can’t afford most products or services. I think loans are risky.'
                name='Tony'
                title="Under-banked worrier"
            />
            </div>
            <div key={4}>
            <SliderCom
                img={P4}
                body="I favour large banks and trust my money with them. I do not use a lot of financial products or services and do not have any desire to start."
                name='Beverly'
                title='Low-maintenance traditionalist'
            />
            </div>
            <div key={5}>
            <SliderCom
                img={P5}
                body="My work is pretty stressful and I’m worried about my retirement. Looking at my finances overwhelms me. "
                name='Sarah'
                title="Unaware and overworked"
            />
            </div>
            <div key={6}>
            <SliderCom
                img={P6}
                body="I am skeptical of large institutions. I don’t have strong financial preferences and spend the majority of my time on my career."
                name='Arianna'
                title='Young and career-focused'
            />
            </div>
          </Slider>
        <div className=" px-2 flex justify-between">
        <Progress per={finalCount}/>
        <div>
            <button className="px-2" onClick={this.next}>
            <img src={Back} className=' ' alt='Back'/>
          </button>
          
        </div> 
        </div>
        </div>
      );
    }
  }
