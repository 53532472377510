import React from 'react'
import D1 from '../../images/tt1.png'
import D2 from '../../images/tt2.png'
import D3 from '../../images/tt3.png'
import D4 from '../../images/tt4.png'
import D5 from '../../images/tt5.png'
import D6 from '../../images/tt6.png'
import D7 from '../../images/tt7.png'
import D8 from '../../images/tt8.png'
import D9 from '../../images/tt9.png'
import D10 from '../../images/tt10.png'
import D11 from '../../images/tt11.png'
import D12 from '../../images/tt12.png'
import ReuseableCard from '../Decentralized/ReuseableCard'


function Contant() {
  return (
    <div>
    <ReuseableCard
        title={"Covered"}
        media={"Green ink on old paper"}
        content={"This piece depicts the present moment as shaped by the hands of our experiences.I enjoyed escaping time and space to capture this dreamy yet lucid vision. A journey I will take time and time again."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335247117983416332/'}
        linkText={"View NFT"}
        image={D1}
    />
     <ReuseableCard
        title={"Twin flame"}
        media={"Ink on paper"}
        content={"Twin flames Burning as one through many incarnations across different times melting the space between."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335239421402021900/'}
        linkText={"View NFT"}
        image={D2}
    />
     <ReuseableCard
        title={"Lavender Moon"}
        media={"Ink on paper"}
        content={"Surrender to the sweet smell of life ...and grow. For what is to come, has already begun."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335241620425277452/'}
        linkText={"View NFT"}
        image={D3}
    />
    <ReuseableCard
        title={"Paradise's Kiss"}
        media={"Ink on paper"}
        content={"What is it to find love? It is to follow the breadcrumbs that lead you to soul. And soul in being found, submits itself to the paradise of a kiss. To give yourself over to being vulnerable, and to possess such power, is the beginning of all that is pure, and true."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335242719936905228/'}
        linkText={"View NFT"}
        image={D4}
    />
    <ReuseableCard
        title={"Ehi"}
        media={"Ink on paper"}
        content={"Ehi, in the Edo language of West Africa, represents a person's true nature or self. The art piece represents the Ehi, at its fullest potential. The head, reminiscent of the style of classical Benin bronze figurines, represents the Ehi's physical incarnation. The fire on the calm-faced head represents a strong divine connection."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335237222378766348/'}
        linkText={"View NFT"}
        image={D5}
    />
     <ReuseableCard
        title={"The Sirian Bell Tower"}
        media={"Ink on paper"}
        content={"Set adrift in solitude, float across the calm waters, follow the toll of the bell ...to that place you call home. The artwork is a reflection on the meditative journey to inner peace and oneness."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335236122867138572/'}
        linkText={"View NFT"}
        image={D6}
    />
    <ReuseableCard
        title={"The Vessel"}
        media={"Ink on paper"}
        content={"The more we give from the vessel, the more space we have to receive."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335238321890394124/'}
        linkText={"View NFT"}
        image={D7}
    />
     <ReuseableCard
        title={"Rebirth"}
        media={"Ink on paper"}
        content={"A rebirth into the present moment."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335240520913649676/'}
        linkText={"View NFT"}
        image={D8}
    />
     <ReuseableCard
        title={"Triune"}
        media={"Ink on paper"}
        content={"The three that is one. Divinity slumming it with earth, blowing wind in sky. See skin etched with time. I am They; Wisdom. The journey to. And the traveler. They, am I: Traveler. To journey. To wisdom. Only the three-legged beast crosses this bridge. Only the Godhead is mortal, only the mortal ascends."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335246018471788556/'}
        linkText={"View NFT"}
        image={D9}
    />
     <ReuseableCard
        title={"Altar of Alignment"}
        media={"Ink on paper"}
        content={"Be still, move beyond all polarities, and know that there are no opposites, only reflections and shadows, and that all is one as one is all ...that you are complete."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335235023355510796/'}
        linkText={"View NFT"}
        image={D10}
    />
     <ReuseableCard
        title={"One Dreamer"}
        media={"Ink on paper and digitally edit."}
        content={"This art piece captures the timeless journey of human consciousness. The conjoined heads represent the layers of reality ...the external and the internal ...seen as separate and yet as one. The dangling beads symbolize the unseen connection between these inner and outer realities."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335244918960160780/'}
        linkText={"View NFT"}
        image={D11}
    />
     <ReuseableCard
        title={"The Many"}
        media={"Ink on paper"}
        content={"What is it to find family? There is no path leading to the woods. There is simply a tree that does not stand alone. And where these souls connect, is where a tree becomes a forest, ...the one that is many."}
        link={'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335243819448533004/'}
        linkText={"View NFT"}
        image={D12}
    />
    </div>
  )
}

export default Contant