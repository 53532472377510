import React from 'react'
import Face from '../Face'
import Sound from '../../Sounds/B4.mp3'
function Problem() {
  return (
    <div className=' px-7 sm:mx-10 lg:mx-20 bg-task-bg grid sm:grid-cols-12 gap-4 lg:py-[96px] sm:py-[48px] pt-[32px] pb-[48px]'>
    <div className='sm:col-span-4'>
    <div className=' sm:flex sm:justify-center'>
    <div id='prob'>
    <Face
      Sound={Sound}
      prevLink='#start'
      nextLink='#pro'
    />
    </div>
    </div>
    
</div>
<div className=' sm:col-span-6'>
   <h1 className='text-[26px] lg:text-[39px] font-[600] sm:w-[80%] text-[#160404] pb-3'>The problem</h1>
   <h1 className='text-[20px] font-[600] sm:w-[80%] text-[#333333] pb-3'>Some early competitive analysis showed that current crowdfunding platforms have user experience designs that do not seem to appeal to younger people.</h1>
   <p className='text-[16px] font-[400] sm:w-[80%] text-[#333333] pb-3'> <b>The opportunity:</b> How do we make crowdfunding sustainable projects attractive to the target users?</p>
</div>
</div>
  )
}

export default Problem