import React, { useEffect } from 'react'
import Footer from '../../Components/Footer'
import Main from '../../Components/Home/Main'
import Navbar from '../../Components/Home/Navbar'
import WOW from 'wowjs';
function Home() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  return (
    <div className=' '>
        <Navbar/>
        <div className=' '>
        <Main/>
        <Footer/>
        </div>
       
    </div>
  )
}

export default Home