import React from 'react'
import BasicCom from './BasicCom'
import Next from '../../images/nexxt.png'
function BasicJouney() {
  return (
    <div className=' sm:mx-10 mx-5 pb-[48px] sm:pb-[64px] lg:pb-[96px] lg:mx-20 sm:grid hidden sm:grid-cols-12 gap-6 '>
        <div className=' sm:col-span-4'>

        </div>
        <div className='sm:col-span-8'>
        <h1 className=' text-[39px]  font-[600] text-[#160404] pb-2'>Basic user journey</h1>
        <p className=' text-[16px] italic font-[600] text-[#333333] lg:w-[80%] pb-2'>*Please note that some sensitive elements of the user flow will not be included in this case study as they are confidential. </p>
        <div className='   mt-[24px] hidden lg:flex gap-3'>
                <BasicCom
                    body='Customer visits Canada Post branch and sees marketing material'
                />
                <div className=' flex flex-col   justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <BasicCom
                    body='Goes online and visits the MyMoney loan website'
                />
                 <div  className=' flex flex-col  justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <BasicCom
                    body='Clicks apply now'
                />
                 <div  className=' flex flex-col  justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <BasicCom
                    body='Goes through authentication and adjudgment process'
                />
           
        </div>
        <div className='   mt-[24px] flex lg:hidden gap-3'>
              <BasicCom
                    body='Customer visits Canada Post branch and sees marketing material'
                />
                <div className=' flex flex-col   justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <BasicCom
                    body='Goes online and visits the MyMoney loan website'
                />
        </div>
        <div className='   mt-[24px] flex lg:hidden gap-3'>
               <BasicCom
                    body='Clicks apply now'
                />
                 <div  className=' flex flex-col  justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <BasicCom
                    body='Goes through authentication and adjudgment process'
                />
        </div>
    </div>
    </div>
   
  )
}

export default BasicJouney