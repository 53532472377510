import React from 'react'
import TextareaAutosize from '@mui/material/TextareaAutosize';

function Textarea({label, value, onChange,name, color, place}) {
  return (
    <div>
        <label className=' mb-2 block'>{label}</label>
        <TextareaAutosize
        className={ `w-full ${color} focus:outline-0 rounded-md p-2`}
      aria-label="minimum height"
      minRows={6}
      name={name}
      value={value}
      onChange={(e) =>onChange(e.target.value)}
      placeholder={place}
      
    />
    </div>
  )
}

export default Textarea