import React from 'react'
import Face from '../Face'
import R1 from '../../images/r22.png'
import R2 from '../../images/r12.png'
import ND from '../../images/nd1.png'
import PD from '../../images/pd1.png'
import mediumZoom from 'medium-zoom'
import MagnifiedImage from '../MagnifiedImage'
import Sound from '../../Sounds/c4.mp3'

function Return() {
    const zoom = React.useRef(mediumZoom())
  return (
    <div className=' bg-task-bg  mx-5 sm:mx-10  px-5  py-[48px] sm:py-[64px] lg:py-[96px] lg:mx-20 grid sm:grid-cols-12 '>
       <div className=' sm:col-span-4 '>
        <div className=' sm:flex sm:justify-center'>
        <div id='return'>
        <Face
            Sound={Sound}
            prevLink='#step'
            nextLink='#loan'
        />
        </div>
        </div>
          
        </div>
        <div className=' sm:col-span-6'>
        <h1 className=' lg:text-[39px] mb-2 font-[600] text-[#160404]'>Returning customer login</h1>
            <div className=' mb-[48px] grid lg:grid-cols-2 rounded border border-[#C0C0C0]'>
                <div>
                    <h1 className=' font-[600] text-[16px] text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Research findings</h1>
                    <div className=' p-5 py-7'>
                        <p className=' pb-4'>It was difficult for participants across the personas, to find the login for returning customers, indicating that it was not visible as a text link.</p>
                        <div>
                        <img src={R1} alt='return'/>
                        </div>
                       
                    </div>
                </div>
                <div>
                    <h1 className=' font-[600] text-[16px] text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Design decisions</h1>
                    <div className=' p-5 py-7'>
                        <p className=' pb-4'>I converted CTA link into a button, and placed it beside the primary CTA button for more visibility. See “new design” below the table.</p>
                        <div>
                        <img src={R2} alt='return'/>
                        </div>
                       
                    </div>
                </div>
            </div>
            <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>New design</h1>
            <div className='mb-[48px]'>
            <MagnifiedImage
                  src={ND}
                    alt="New design"
                    zoom={zoom.current}
                    background="white"
            />
              
            </div>
            <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>Previous design</h1>
            <div className='mb-[48px]'>
            <MagnifiedImage
                  src={PD}
                    alt="Previous design"
                    zoom={zoom.current}
                    background="white"
            />
               
            </div>
        </div>
    </div>
  )
}

export default Return