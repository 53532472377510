import React, {useRef, useState} from 'react'
import AddsButtion from '../SelectValue/AddButtion'
import Textarea from '../SelectValue/Textarea'
import TextBox from '../SelectValue/TextBox'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Loading from '../SelectValue/Loadingdropdown';
function Contactus() {
    const form = useRef();
    const [open, setOpen] = useState(false)
  const sendEmail = (e) => {
    e.preventDefault();
    setOpen(true)
    emailjs.sendForm('service_y5b81qc', 'template_gny8fhi', form.current, 'TADsQHIzOyPE8sq_t')
      .then((result) => {
        setOpen(false)
          console.log(result.text);
          toast("Message sent sucessfully")
          e.target.reset()
      }, (error) => {
        setOpen(false)
          console.log(error.text);
      });
  };
  return (
    <div className=' bg-white   rounded-t-2xl   pt-[96px] pb-[120px]' id='contact'>
    <Loading
        open={open}
    />
    <ToastContainer />
        <div className='  w-full '>
        <h1 className=' text-[23px] sm:text-[26px] md:text-[32px] font-[600] text-header-text mb-3'>Let’s connect</h1>
       
            <div className=' w-full'>
            <form ref={form} onSubmit={sendEmail}>
               
               <div className=' my-3'>
               <TextBox
                   place='Your name'
                   type='text'
                   name="user_name"
                   color=' bg-[#EFF0F2]'
               />
               </div>
               <div className=' my-3'>
               <TextBox
                   place='Your email'
                   type='email'
                   name="user_email"
                   color=' bg-[#EFF0F2]'
               />
               </div>
               <div className=' my-3'>
               <TextBox
                   place='Your subject'
                   type='text'
                   name='email_subject'
                   color=' bg-[#EFF0F2]'
               />
               </div>
               <div className=' min-w-fit relative my-5'>
                   <Textarea 
                       place='Your message'
                       name='message'
                       color=' bg-[#EFF0F2]'
                   />
                   <div className=' absolute bottom-3 right-2 '>
                   <AddsButtion
                       name='Send'
                   />
               </div>
               </div>
               
           </form>
            </div>
           
        </div>
    </div>
  )
}

export default Contactus