import React, { useRef } from 'react'
import Face from '../Face'
import ND from '../../images/nd6.png'
import PD from '../../images/pd6.png'
import mediumZoom from 'medium-zoom'
import MagnifiedImage from '../MagnifiedImage'
import Sound from '../../Sounds/c9.mp3'
function FinancialLit() {
    const zoom = useRef(mediumZoom())
  return (
    <div className='    px-5 md:mx-10 py-[48px] sm:py-[64px] lg:py-[96px] lg:mx-20 grid sm:grid-cols-12 '>
    <div className=' sm:col-span-4 '>
        <div className=' sm:flex sm:justify-center'>
        <div id='fin'>
        <Face
            Sound={Sound}
            prevLink='#score'
            nextLink='#image'
        />
        </div>
        </div>
          
        </div>
    <div className=' sm:col-span-6'>
    <h1 className=' lg:text-[39px] text-[26px] sm:py-0 py-3 mb-2 font-[600] text-[#160404]'>Financial literacy blog articles</h1>
        <div className=' mb-[48px] grid lg:grid-cols-2 rounded border border-[#C0C0C0]'>
            <div>
                <h1 className=' font-[600] text-[16px] text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Research findings</h1>
                <div className=' p-5 py-7'>
                    <p className=' pb-4'>Blog articles were seen to resonate with the majority of participants, though a small minority felt they would not, in general, interact with this kind of content.</p>
                   
                </div>
            </div>
            <div>
                <h1 className=' font-[600] text-[16px] text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Design decisions</h1>
                <div className=' p-5 py-7'>
                    <p className=' pb-4'>Design 2 extra blog articles on financial topics the participants were curious about. I also designed the blog cards in an accessible carousel form. See “new design” below the table.</p>
                   
                </div>
            </div>
        </div>
        <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>New design</h1>
        <div className='mb-[48px]'>
        <MagnifiedImage
                  src={ND}
                    alt="New design"
                    zoom={zoom.current}
                    background="white"
            />
        </div>
        <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>Previous design</h1>
        <div className='mb-[48px]'>
        <MagnifiedImage
                  src={PD}
                    alt="Previous design"
                    zoom={zoom.current}
                    background="white"
            />
        </div>
    </div>
</div>
  )
}

export default FinancialLit