import React from 'react'

function CvCom({year, con}) {
  return (
    <div className=' flex gap-3 my-3'>
        <p className=' text-[#7E7986] font-bold text-[12px]'>{year}</p>
        <p className=' text-[#7E7986] font-[500] text-[12px]'>{con}</p>
    </div>
  )
}

export default CvCom