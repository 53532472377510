import React from 'react'
import Face from '../Face'
import Jm from '../../images/jm1.png'
import Jm1 from '../../images/jm2.png'
import mediumZoom from 'medium-zoom'
import MagnifiedImage from '../MagnifiedImage'
import Sound from '../../Sounds/B7.mp3'
function Journey() {
  const zoom = React.useRef(mediumZoom())
  return (
    <div className=' px-7 sm:mx-10 lg:mx-20 grid sm:grid-cols-12 gap-4 lg:py-[96px] sm:py-[48px] pt-[32px] pb-[48px]'>
    <div className=' sm:col-span-4'>
    <div className=' sm:flex sm:justify-center'>
    <div id='map'>
    <Face
      Sound={Sound}
      prevLink='#study'
      nextLink='#sketching'
    />
    </div>
    </div>
        
    </div>
    <div className=' sm:col-span-8'>
    <h1 className='text-[26px] lg:text-[39px] font-[600] sm:w-[80%] text-[#160404] pb-3'>Journey mapping</h1>
        <p className='text-[16px] font-[400] sm:w-[80%] text-[#333333] pb-5'> We created journey maps for the two main user groups, funders and fund beneficiaries, and shared it with the team.</p>
        <div className=' mb-[48px] '>
            <MagnifiedImage
                  src={Jm}
                    alt="Journey mapping"
                    zoom={zoom.current}
                    background="white"
            />
        </div>
        <div className=' mb-[48px] '>
        <MagnifiedImage
                  src={Jm1}
                    alt="Journey mapping"
                    zoom={zoom.current}
                    background="white"
            />
        </div>
    </div>
</div>
  )
}

export default Journey


