import React from 'react'
import Face from '../Face'
import Screend from './Screend'
import Sound from '../../Sounds/B10.mp3'
function Highfidelity() {
  return (
    <div className=' px-7 sm:mx-10 lg:mx-20 bg-task-bg grid sm:grid-cols-12 gap-5 lg:py-[96px] sm:py-[48px] pt-[32px] pb-[48px]'>
         <div className=' sm:col-span-4'>
        <div className=' sm:flex sm:justify-center'>
        <div id='fidelity'>
            <Face
              Sound={Sound}
              prevLink='#wireframes'
              nextLink='#test'
            />
        </div>
        </div>
      </div>
        <div className=' sm:col-span-6'>
        <h1 className='text-[26px] lg:text-[39px] font-[600] sm:w-[80%] text-[#160404] pb-3'>High fidelity designs</h1>
        <p className=' text-[16px] text-[#333333] font-[400]'>
        Gamification patterns played a big part in my design explorations.
        <br></br> <br></br>
        I tried out some gamification features that allow users to earn coins, and use them to unlock perks.
        <br></br> <br></br>
        Also, I tried out features that allow users to earn real money by watching adverts. 
        </p>
        <div className='hidden sm:block'>
        <Screend/>
       
       
        <div id='prototype'>
        <iframe className="w-full aspect-video ..." width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FrkZtCP93MSiXQRWQ5GqpwN%2FBoosty%3Fpage-id%3D0%253A1%26node-id%3D1%253A1386%26viewport%3D1566%252C371%252C0.19%26scaling%3Dscale-down%26starting-point-node-id%3D1%253A2586" title='Protoptye' allowfullscreen></iframe>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Highfidelity