import React from 'react'
import Face from '../Face'
import Sound from '../../Sounds/B3.mp3'
function Starting() {
  return (
    <div className=' px-7 sm:mx-10 lg:mx-20 grid sm:grid-cols-12 gap-4 lg:py-[96px] sm:py-[48px] pt-[32px] pb-[48px]'>
    <div className=' sm:col-span-4'>
    <div className=' sm:flex sm:justify-center'>
    <div id='start'>
    <Face
      Sound={Sound}
      prevLink='#business'
      nextLink='#prob'
    />
    </div>
    </div>
   
       
    </div>
    <div className=' sm:col-span-6'>
    <h1 className='text-[26px] lg:text-[39px] font-[600] sm:w-[80%] text-[#160404] pb-3'>Starting assumption</h1>
        <p className='text-[16px] font-[400] sm:w-[80%] text-[#333333] pb-3'>After preliminary interview sessions, we established that the target user can’t afford the stripped down energy kits. 
            <br></br><br></br>
            So we thought… <b>why not explore a crowdfunding model?</b> 
            <br></br><br></br>
            The task: Design a Boosty crowdfunding solution and test with target users.
            </p>
    </div>
</div>
  )
}

export default Starting