import React, { useEffect } from 'react'
import Main from '../../Components/art/Main'
import Navbar from '../../Components/art/Navbar'
import WOW from 'wowjs';
function Arts() {
    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])
  return (
    <div className='   bg-[#1D1B21] h-full '>
        <Navbar/>
        <div className='  '>
        <Main/>
        </div>
       
    </div>
  )
}

export default Arts