import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../Components/Aspired/Navbar'
import Topsec from '../../Components/Aspired/Topsec'
import Footer from '../../Components/Footer'
import Contactus from '../../Components/Home/Contactus'
import Back from '../../images/ff.svg'
import Forward from '../../images/bb.svg'

function Aspire() {
  return (
    <div className=' relative'>
      <div className='top-[520px] hidden sm:block fixed left-5'>
    <Link to='/boosty'><img src={Back} alt='Back'/></Link>  
      </div>
      <div className='top-[520px] hidden sm:block fixed right-5'>
     <Link to='/canadapoststudy'><img src={Forward} alt='Back'/></Link> 
      </div>
        <Navbar/>
        <Topsec/>
        <div className=' grid sm:grid-cols-12 gap-5'>
            <div className=' sm:col-span-4'>

            </div>
            <div className='sm:col-span-8 mx-5  sm:mx-10 lg:mx-20'>
            <Contactus/>
            </div>
            
        </div>
       <Footer/>
    </div>
  )
}

export default Aspire