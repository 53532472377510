import React, { useState } from 'react'
import Head from '../../images/ai.png'
import Heada from '../../images/aiS.png'
import Icoon1 from '../../images/icc1.svg'
import Iconn2 from '../../images/icc2.svg'
import Iconn3 from '../../images/icc3.svg'
import Collection from './Collection'
import Bio from './Bio'
import Contactus from './Contact'
import NewProject from './NewProject'
function Main() {
  const [fixed, setFixed] = useState(false)

 function checkScroll  () {
      if(window.scrollY >= 0){
        setFixed(true)
      }else{
        setFixed(false)
      }
 }

 window.addEventListener("scroll", checkScroll)
  return (
    <div className= ' mx-5 sm:mx-20 sm:pt-5  h-full'>
        
        <div className='sm:fixed px-5 sm:top-[6%]  sm:mt-12 sm:left-0 '>
        <div className= ' sm:flex sm:w-1/2' >
            <div className=' sm:px-20'>
            <div className=' flex md:block pt-5 sm:pt-0 justify-between sm:justify-center '>
            <div className=' pt-20 sm:pt-0'>
            {/* <div className=' bg-[#7210EE]  mb-4 p-4'>
            <p className=' text-[#FAFAFA] font-[400] text-[14]'> <ReportProblemOutlinedIcon/> The art page is still under construction, so some elements are not clickable.</p>
        </div> */}
        <div className=' flex sm:block gap-4'>
            <div>
            <img src={Head}  className='mx-auto hidden sm:block' alt='head'/> 
               <img src={Heada} className=' sm:hidden  py-5' alt='head'/> 
            </div>
            <div className=' flex sm:hidden flex-col justify-center gap-4'>
            <div className=' flex gap-4'>
            <a href='https://linktr.ee/Ai_dezign' target='_blank' rel='noreferrer'><img src={Icoon1} alt='Icon'/> </a>  
              <a href='#contact'>  <img src={Iconn2}  alt='Icon'/></a>
               <a href='https://medium.com/@aithelight/dcentralizm-divining-unity-38a4a2a9627c?source=friends_link&sk=70dda116cf96e73efd993ee7344d9bf9' target='_blank' rel='noreferrer'> <img src={Iconn3} alt='Icon'/> </a>  
               </div>
            </div>
              </div>
               <div className=' pb-4 sm:flex xl:justify-center'>
               <p className=' text-[#9E99A5] font-normal lg:text-center text-[16px]   '>You can also call me Ai (pronounced “I”). 
                  I am the universe masquerading as an artist. I create art to transform and explore my subconscious.</p>
               </div>
                <div className=' wow animate__animated animate__zoomInUp flex pt-3  justify-center'>
                <div className=' sm:flex hidden  gap-4'>
                <a href='https://linktr.ee/Ai_dezign' target='_blank' rel='noreferrer'><img src={Icoon1} alt='Icon'/> </a>  
              <a href='#contact'><img src={Iconn2} className='' alt='Icon'/></a> 
               {/* <img src={Iconn22} className=' hidden hover:block' alt='Icon'/> */}
               <a href='https://medium.com/@aithelight/dcentralizm-divining-unity-38a4a2a9627c?source=friends_link&sk=70dda116cf96e73efd993ee7344d9bf9' target='_blank' rel='noreferrer'> <img src={Iconn3} alt='Icon'/> </a>  
              
                </div>
                    
                </div>
                </div>
                </div>
               
            </div>
            </div>
        </div>
        <div className=' sm:grid sm:grid-cols-2  sm:gap-4 sm:pt-10 md:mx-5 sm:overflow-y-auto'>
        <div className='hidden sm:block'>

        </div>
          <div className= 'sm:mr-10 pt-5 sm:z-30' >
          <NewProject/>
          <Collection/>
            {/* <Recent/> */}
            <Bio/>
            <Contactus/>
            <div className=' flex justify-end'>
            <p className=' text-[#7E7986]'>© Ai dezign</p>
            </div>
           
          </div>
            
        </div>
    </div>
  )
}

export default Main