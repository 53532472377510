import React from 'react'

export default function TextBox({value, onChange, label,place,name, color, type, bool}) {
  return (
    <div>
       <label className=' block mb-2'>{label}</label> 
       <input 
       id="outlined-basic"
        variant="outlined"
        value= {value}
        name={name}
        placeholder={place}
        onChange={(e) => onChange(e.target.value)} 
        required
        type={type}
        className= { `rounded w-full  ${color} p-5 focus:outline-0`}
         />
     
    </div>
  )
}
