import React from 'react'
import Face from '../Face'
import Next from '../../images/nexxt.png'
import ProcessCom from './ProcessCom'
import Sound from '../../Sounds/B5.mp3'
function Process() {
  return (
    <div className=' px-5 sm:mx-10 lg:mx-20 grid sm:grid-cols-12 gap-4 lg:py-[96px] sm:py-[48px] pt-[32px] pb-[48px]'>
        <div className=' sm:col-span-4'>
        <div className=' sm:flex sm:justify-center'>
        <div id='pro'>
        <Face
            Sound={Sound}
            prevLink='#prob'
            nextLink='#study'
        />
        </div>
        </div>
        
           
        </div>
        <div className=' sm:col-span-8'>
            <h1 className='text-[20px] font-[600] sm:w-[80%] text-[#160404] pb-3'>The process</h1>
            <div className='   mt-[24px] hidden xl:flex gap-3'>
                <ProcessCom
                    body={<div>
                        <p className=' text-[#160404] font-[600] text-[20px] mb-3'>Research</p>
                        <p>Competitor study</p>
                        <p>+</p>
                        <p>Journey mapping</p>
                    </div>}
                />
                <div className=' flex flex-col   justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <ProcessCom
                     body={<div>
                        <p className=' text-[#160404] font-[600] text-[20px] mb-3'>Ideation</p>
                        <p>Sketching</p>
                    </div>}
                />
                 <div  className=' flex flex-col  justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <ProcessCom
                    body={<div>
                        <p className=' text-[#160404] font-[600] text-[20px] mb-3'>Design</p>
                        <p>Wireframing</p>
                        <p>+</p>
                        <p>High fidelity</p>
                    </div>}
                />
                 <div  className=' flex flex-col  justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <ProcessCom
                    body={<div>
                        <p className=' text-[#160404] font-[600] text-[20px] mb-3'>Research</p>
                        <p>Validation</p>
                        <p>+</p>
                        <p>Usability tests</p>
                    </div>}
                />
           
        </div>
        <div className='   mt-[24px] flex xl:hidden gap-3'>
          <ProcessCom
                    body={<div>
                        <p className=' text-[#160404] font-[600] text-[20px] mb-3'>Research</p>
                        <p>Competitor study</p>
                        <p>+</p>
                        <p>Journey mapping</p>
                    </div>}
                />
                <div className=' flex flex-col   justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <ProcessCom
                     body={<div>
                        <p className=' text-[#160404] font-[600] text-[20px] mb-3'>Ideation</p>
                        <p>Sketching</p>
                    </div>}
                />
        </div>
        <div className='   mt-[24px] flex xl:hidden gap-3'>
            <ProcessCom
                    body={<div>
                        <p className=' text-[#160404] font-[600] text-[20px] mb-3'>Design</p>
                        <p>Wireframing</p>
                        <p>+</p>
                        <p>High fidelity</p>
                    </div>}
                />
                 <div  className=' flex flex-col  justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <ProcessCom
                    body={<div>
                        <p className=' text-[#160404] font-[600] text-[20px] mb-3'>Research</p>
                        <p>Validation</p>
                        <p>+</p>
                        <p>Usability tests</p>
                    </div>}
                />
        </div>
            </div>
    </div>
  )
}

export default Process