import React, { useRef, useState } from 'react'
import TextBox from '../SelectValue/TextBox'
import F1 from '../../images/feed.png'
import F2 from '../../images/feedM.png'
import Textarea from '../SelectValue/Textarea'
import AddButton from '../SelectValue/AddButtion'
import {ToastContainer,  toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import Loading from '../SelectValue/Loadingdropdown'
function Content() {
    const form = useRef();
    const [open, setOpen] = useState(false)
    const sendEmail = (e) => {
        e.preventDefault();
        setOpen(true)
        emailjs.sendForm('service_y5b81qc', 'template_o16m8u9', form.current, 'TADsQHIzOyPE8sq_t')
          .then((result) => {
            setOpen(false)
              console.log(result.text);
              toast("Message sent sucessfully")
              e.target.reset()
          }, (error) => {
            setOpen(false)
              console.log(error.text);
          });
      };
  return (
    <div className='  bg-task-bg px-5  rounded  md:gap-10 py-5 md:h-[85vh] flex-col-reverse lg:flex-row   sm:mx-10 lg:mx-20 flex'>
    <div className=' px-4 pt-8 md:pt-24'>
    <h1 className=' mb-3 lg:text-[39px]  sm:w-[80%] sm:text-[32px] text-[25px] font-[600]'>Hey, how was your website experience?</h1>
    <p className=' mb-3  text-[16px]  sm:w-[80%] font-[400]'>I would love to get your honest feedback. Did you like the website? Was it easy to find what you came here for? </p>

    <div className=' sm:w-[80%]'>
    <Loading
        open={open}
    />
     <ToastContainer />
    <form ref={form} onSubmit={sendEmail}>
        <div>
        <TextBox
                place='Your email'
                name='user_email'
                type='email'
            />
        </div>
        <p className='mb-3 mt-5 text-[16px]  sm:w-[80%] font-[600]'>Please provide your comments below:</p>
        <div className=' min-w-fit relative my-5'>
           <Textarea 
               place='Your message'
               name='message'
           />
          
           <div className=' absolute bottom-3 right-2 '>
           <AddButton
               name='Submit'
           />
       </div>
        </div>
        <div>
       
       </div>
        </form>
    </div>
    </div>
    <div className=' sm:flex sm:flex-col sm:justify-center'>
        <img src={F1} className=' hidden md:block' alt='Feedback'/>
        <img src={F2} className=' md:hidden block' alt='Feedback'/>
    </div>
</div>
  )
}

export default Content