import React from 'react'

function MeasurementCom({count, subject, body}) {
  return (
    <div className=' bg-[#E3E0EA] rounded p-5 flex flex-col justify-center'>
        <div>
            <p className=' text-center text-[25px] mb-1 text-[#160404] font-[600]'>{count}</p>
            <p className=' text-center text-[20px] mb-4 text-[#160404] font-[400]'>{subject}</p>
            <p className=' text-center text-[14px] mb-2 text-[#160404] font-[400]'>{body}</p>
        </div>
    </div>
  )
}

export default MeasurementCom