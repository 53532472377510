import React, { useRef, useState } from 'react'
import Faces from '../images/facee.png'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/Pause';
import Tooltip from '@mui/material/Tooltip';

import Slider from './Slider/Slider';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Audiominutes from './Audiominutes';

function Face({Sound, prevLink, nextLink}) {
  const [percentage, setPercentage] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
 
  const audioRef = useRef()

  const onChange = (e) => {
    const audio = audioRef.current
    audio.currentTime = (audio.duration / 100) * e.target.value
    setPercentage(e.target.value)
  }

  const play = () => {
    const audio = audioRef.current
    audio.volume = 1

    if (!isPlaying) {
      setIsPlaying(true)
      audio.play()
    }

    if (isPlaying) {
      setIsPlaying(false)
      audio.pause()
    }
  }
  const getCurrDuration = (e) => {
    const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
    const time = e.currentTarget.currentTime

    setPercentage(+percent)
    setCurrentTime(time.toFixed(2))
  }
  return (
    <>
     <audio
        ref={audioRef}
        onTimeUpdate={getCurrDuration}
        onLoadedData={(e) => {
          setDuration(e.currentTarget.duration.toFixed(2))
        }}
        src={Sound}
      ></audio>
        <div className='relative mb-3'>
   
    <img src={Faces} width='134' height='122' className=' relative mx-auto' alt='Face'/>
        <div className=' '>
        {isPlaying ?<PauseIcon onClick={play} fontSize='large' className=' text-white absolute top-16 left-1/2 transform -translate-x-1/2 -translate-y-1/2'/> : <>
        
          <Tooltip title="Don’t have time to read? Listen instead." arrow >
            <PlayArrowRoundedIcon onClick={play} fontSize='large' className=' text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'/>
            </Tooltip>
            
        </>}
        </div>
        {isPlaying && 
          <div className=' absolute left-10 sm:-left-9 top-12 '>
         <a href={prevLink}><SkipPreviousIcon onClick={play} fontSize='large'/></a> 
        </div>
        }
        {isPlaying && 
          <div className=' absolute right-10 sm:-right-9 top-12 '>
        <a href={nextLink}><SkipNextIcon onClick={play} fontSize='large'/></a>  
        </div>
        }
       
        {isPlaying && 
          <div className=' '>
       <Audiominutes
        duration={duration}
        currentTime={currentTime}
       />
       <Slider percentage={percentage} onChange={onChange}/>
       </div>
        }
      
</div>
    </>
  
  )
}

export default Face
