import React from 'react'
import { Link } from 'react-router-dom'


function Public() {
  return (
    <div className=' sm:mb-[64px] mb-[24px] md:mb-[86px]'>
          <h1 className=' text-[#BAB3C3] text-3xl font-medium mb-4 leading-10'>Public art collection </h1>
          <Link to={'/summer'}>

        
          <div className=' relative dd rounded hover:border-[#A157FF] hover:border-[3px]   mb-2'>
            <p className=' inset-center text-[#9C98A3] font-[400] text-[16px] '>Summer connections</p>
            </div>
            <p className='text-[#9E99A5] mb-4 text-base font-normal  leading-normal'>A six-piece banner series commissioned by Mississauga City.</p>
            </Link>
    </div>
  )
}

export default Public