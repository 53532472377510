// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import{
    getFirestore, collection, getDocs
} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDTqMt9JkF5jCZboi0yK-Xfe0p0x2JOCe8",
  authDomain: "mosquepay-96c29.firebaseapp.com",
  projectId: "mosquepay-96c29",
  storageBucket: "mosquepay-96c29.appspot.com",
  messagingSenderId: "216888327375",
  appId: "1:216888327375:web:14eadc128fcf343f17cbba"
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const db = getFirestore()

// const colRef = collection(db, 'likes')

