import React from 'react'
import { Link } from 'react-router-dom'
import Bussiness from '../../Components/Boosty/Bussiness'
import Compitition from '../../Components/Boosty/Compitition'
import Conclusion from '../../Components/Boosty/Conclusion'
import Contactusss from '../../Components/Boosty/Contactus'
import Highfidelity from '../../Components/Boosty/Highfidelity'
import Intro from '../../Components/Boosty/Intro'
import Journey from '../../Components/Boosty/Journey'
import Navbar from '../../Components/Boosty/Navbar'
import Problem from '../../Components/Boosty/Problem'
import Process from '../../Components/Boosty/Process'
import Screend from '../../Components/Boosty/Screend'
import Sk1 from '../../Components/Boosty/Sk1'
import Sk2 from '../../Components/Boosty/Sk2'
import Sk3 from '../../Components/Boosty/Sk3'
import Sk4 from '../../Components/Boosty/Sk4'
import Sketching from '../../Components/Boosty/Sketching'
import Starting from '../../Components/Boosty/Starting'
import Topsec from '../../Components/Boosty/Topsec'
import Usability from '../../Components/Boosty/Usability'
import Wireframe from '../../Components/Boosty/Wireframe'
import Footer from '../../Components/Footer'
import Back from '../../images/ff.svg'
import Forward from '../../images/bb.svg'

function Boosty() {
  return (
    <div className='relative'>
      <div className='top-[520px] hidden sm:block fixed left-5'>
    <Link to='/canadapoststudy'><img src={Back} alt='Back'/></Link>  
      </div>
      <div className='top-[520px] hidden sm:block fixed right-5'>
     <Link to='/canadapoststudy'><img src={Forward} alt='Back'/></Link> 
      </div>
        <Navbar/>
       <Topsec/>
       
       <Intro/>
       <Bussiness/>
       <Starting/>
       <Problem/>
       <Process/>
       <Compitition/>
       <Journey/>
       <Sketching/>
       <div className=' px-5 bg-task-bg sm:hidden'>
          <Sk1/>
          <Sk2/>
          <Sk3/>
          <Sk4/>
       </div>
       <Wireframe/>
       <Highfidelity/>
       <div className='px-5 pb-[48px] bg-task-bg sm:hidden'>
        <Screend/>

        <div id='prototypes'>
        <iframe className="w-full aspect-video ... h-[70vh]"  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FrkZtCP93MSiXQRWQ5GqpwN%2FBoosty%3Fpage-id%3D0%253A1%26node-id%3D1%253A1386%26viewport%3D1566%252C371%252C0.19%26scaling%3Dscale-down%26starting-point-node-id%3D1%253A2586" title='Protoptye' allowfullscreen></iframe>
        </div>
       </div>
       <Usability/>
       <Conclusion/>
       <Contactusss/>
       <Footer/>
    </div>
  )
}

export default Boosty