import React from 'react'
import Content from '../../Components/Feedback/Content'
import Navbar from '../../Components/Feedback/Navbar'

function index() {
  return (
   <div>
    <Navbar/>
    <Content/>
   </div>
  )
}

export default index