import React, {useRef} from 'react'

function MagnifiedImage({ zoom, src, alt, background }) {
  const zoomRef = useRef(zoom.clone({ background }))

  function attachZoom(image) {
    zoomRef.current.attach(image)
  }

  return <img src={src} className=' w-full' alt={alt} ref={attachZoom} />
}

export default MagnifiedImage
