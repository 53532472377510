import React from 'react'
import { Link } from 'react-router-dom'
import Feedback from '../images/feedb.svg'
function Footer() {
  return (
    <div className=' flex mx-10 mt-2  border-t border-t-[#DADADA] p-3 justify-between lg:mx-20'>
        <Link to='/feedback'>
        <div className=' flex'>
            <img src={Feedback} alt='feedback'/>
            <p className=' hover:text-[#7210EE] text-[16px] font-[400] text-[#160404]'>Website feedback</p>
        </div>
        </Link>
        
        <div>
            <p className=' text-[14px] font-[400] text-[#160404]'>© Aimua</p>
        </div>
    </div>
  )
}

export default Footer