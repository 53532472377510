import React from 'react'
import { Link } from 'react-router-dom'
import Like from '../../images/like.svg'
import Eye from '../../images/eye.png'
function ProjectCom1({img, con, color, likes, link, bool, view}) {
  return (
  
    <div className='  md:mx-5' >
       
            <div className={bool ?`${color} py-14  px-3 grid  xl:grid-cols-2 gap-4 rounded `:`${color} py-14 transition ease-in-out hover:border hover:border-gray-300 delay-150 hover:-translate-y-1 hover:scale-105 duration-300 px-3 grid  xl:grid-cols-2 gap-4 rounded`}>
            <Link to={link}>
                <div className=' flex flex-col justify-center'>
                    <img src={img} className='mx-auto' alt='img'/>
                </div>
                </Link>
                <div className=' flex flex-col justify-center'>
                    <p className=' text-primary-color xl:w-[70%] pb-4 text-[14px] lg:text-[16px] font-normal'>{con}</p>
                </div>
            </div>
            <Link to={link}>
            <div className=' flex justify-end gap-4 pt-4 '>
                <div className=' flex gap-1'>
                   <img src={Like} alt='Likes'/><span className=' text-[14px] font-[600] text-[#535265]'>{likes}</span>  
                </div>
                <div className=' flex'>
                <div>
                <img src={Eye} className='pr-1 pt-1' alt='Views'/> 
                </div>
                 <span className=' text-[14px] font-[600] text-[#535265]'>{view}</span> 
                </div>
            </div>
            </Link>
    </div>
  )
}

export default ProjectCom1