import React from 'react'
import Face from '../Face'
import Sound from '../../Sounds/B11.mp3'
function Usability() {
  return (
    <div className=' px-7 sm:mx-10 mb-[96px] lg:mx-20  grid sm:grid-cols-12 gap-4 lg:py-[96px] sm:py-[48px] pt-[32px] pb-[48px]'>
              <div className=' sm:col-span-4'>
        <div className=' sm:flex sm:justify-center'>
        <div id='test'>
            <Face
              Sound={Sound}
              prevLink='#fidelity'
              nextLink='#conclusion'
            />
        </div>
        </div>
      </div>
        <div className=' sm:col-span-6'>
            <h1 className='text-[26px] lg:text-[39px] font-[600] sm:w-[80%] text-[#160404] pb-3'>Usability test</h1>
            <p className='text-[16px] font-[400] sm:w-[80%] text-[#333333] pb-3'>We tested ten participants recruited from a business school group, and an Instagram chat group. We tested for the ease of task completion, the usability of gamification features, visibility of CTA buttons, and over all app experience.</p>
            <div className=' mt-3'>
            <h1 className='text-[22px] lg:text-[32px] font-[600] sm:w-[80%] text-[#160404] pb-3'>Findings</h1>
            <div>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; 70% found the boosty reward coins to be exciting.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; 90% though the ad earner was a feature they would like to use for real.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; 40% said the onboarding screens were too many.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; 70% found the swipe functionality easy to use.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; 50% expressed confusion about the “pledge” CTA.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; 100% completed the “giving” task.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; 2 user asked for the option to give all their Ad earnings to businesses.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; 1 user asked for an automatic/scheduled giving assistance.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; 40% of users were not sure they would want to give their money out.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; 1 person suggested that the app would be better suited to corporations. </p>
            </div>
            </div>
            <div className=' mt-3'>
            <h1 className='text-[22px] lg:text-[32px] font-[600] sm:w-[80%] text-[#160404] pb-3'>Recommendations</h1>
            <div>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; Retitle “pledge” CTA to an easily recognized word like “Give”.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; Reduce the number of onboarding screens.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; Add a button that allows users to give all their ad earnings as donations.</p>
                <p className=' text-[#333333] font-[400] text-[16px]'>&#x2022; Consider adding a subscription feature in the future that allow for automatic fund allocation.</p>
                 </div>
            </div>
        </div>
    </div>
  )
}

export default Usability