import React from 'react'
import Main from '../../Components/Cv/Main'
import Navbar from '../../Components/Cv/Navbar'

function Cv() {
  return (
    <div className='   bg-[#1D1B21] h-full '>
    <Navbar/>
    <div>
     <Main/>
    </div>
</div>
  )
}

export default Cv