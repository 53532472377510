import React from 'react'
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
function ReuseableCard({image, header, title, media, content, link, linkText}) {
  return (
    <div className=' mb-16'>
        
         <h1 className=' text-gray-400 text-3xl font-medium mb-4 leading-10'>{header}</h1>
         <div className=' p-4 bg-[#110F14] border-[0.3px] border-[#7E7986] rounded grid md:grid-cols-2 gap-5 '>
            <div>
                <img src={image} className=' rounded w-full' alt={title}/>

            </div>
            <div>
                <p className=' text-[14px] font-[400] text-[#7E7986] mb-4'><span className=' font-[700]'>Title:  </span>{title}</p>
                <div className=' flex gap-2  mb-4'>
                    <p className=' text-[14px] font-[700] text-[#7E7986]'>Media:</p>
                    <p className=' text-[14px] font-[400] text-[#7E7986]'>{media}</p>
                </div>
                <p className=' text-[14px] font-[400] text-[#7E7986] mb-6'>{content}</p>
                {linkText.length > 0 && 
                <a href={link} target='_blank' rel='noreferrer'>
                <div className=' flex gap-4'>
                    <p className=' font-semibold text-[#A157FF] underline  text-[16px]'>{linkText}</p>
                    <LaunchTwoToneIcon className='text-[#A157FF]  '/>
                </div>
                </a>
                }
                
               
            </div>
         </div>
    </div>
  )
}

export default ReuseableCard