import React from 'react'
import { NavLink } from 'react-router-dom'

function Navbar() {
  return (
    <div className='fixed bg-white  z-50  px-5 sm:px-10 lg:px-10  w-full'>
 <div className='  p-3 flex  border-b border-b-gray-300 justify-between'>
     <div className=' flex border  relative border-gray-200 rounded-[18px]'>
     <NavLink
        className={({isActive}) =>{
            return( ' text-[#313030] p-1.5 pl-6 text-start  pr-5 ' + (
         !isActive ? ' hover:text-[#7210EE] text-center bg-[#D9D9D9] rounded-l-[18px]' : ' px-2 bg-white -ml-4 text-center text-[#7210EE] border border-[#C4C4C4] rounded-[18px]'
         ))
          }} 
         to='/art'>
          Art
    </NavLink>
    <NavLink 
        className={({isActive}) =>{
        return( ' text-[#313030] p-1.5 pl-6 text-start  pr-5 ' + (
         !isActive ? ' hover:text-[#7210EE] text-center bg-[#D9D9D9] rounded-l-[18px]' : ' px-2 bg-white text-center -ml-4 text-[#7210EE] border border-[#C4C4C4] rounded-[18px]'
         ))
          }} 
         to='/'>
          Design
    </NavLink>
     </div> 
     <div className='  flex gap-6 pt-2 justify-between'>
          <a className=' hover:text-[#7210EE] text-[14px]' href='https://www.figma.com/exit?url=https%3A%2F%2Fdrive.google.com%2Ffile%2Fd%2F1vN7ObPfX2H75OlXM5kD3rjEBCMpzR3Do%2Fview%3Fusp%3Dsharing' target="_blank" rel="noreferrer" >Resume</a>
          <a href='#project' className=' hidden text-[14px] hover:text-[#7210EE] md:block'>Projects</a>
          <a href='#review' className=' hidden text-[14px] hover:text-[#7210EE] md:block'> Reviews</a>
          <a href='#contact' className=' hidden text-[14px] hover:text-[#7210EE]  md:block'>Contact me</a>
     </div>  
    </div>
    </div>
   
  )
}

export default Navbar