import React from 'react'
import Face from '../Face'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import MeasurementCom from './MeasurementCom';
import Sound from '../../Sounds/c11.mp3'
function Measurement() {
  return (
    <div className=' md:mx-10 px-5  lg:mx-20 pt-[48px] sm:pt-[64px] lg:pt-[96px] grid sm:grid-cols-12  gap-5'>
         <div className=' sm:col-span-4 '>
        <div className=' sm:flex sm:justify-center'>
        <div id='measure'>
        <Face
            Sound={Sound}
            prevLink='#fin'
            nextLink='##'
        />
        </div>
        </div>
          
        </div>
        <div className=' sm:col-span-6'>
            <h1 className=' text-[#160404] font-[600] mb-3 text-[26px] lg:text-[39px] '>Measurements</h1>
            <p className=' text-[16px] mb-3 font-[400] text-[#333333]'>Here are measurements of audience engagement from September 12(launch date) to October 5 2022.</p>
           <a href='https://www.canadapost-postescanada.ca/cpc/en/personal/mymoney/loan.page' target='_blank' rel='noreferrer'> <p className=' text-purple-600 text-[16px] mb-3 font-[600]'>Click here to visit live website <LaunchRoundedIcon/></p></a>
            <p className=' italic text-[#333333] text-[16px] mb-[24px] font-[600]'>*Please note that loan applications were temporarily suspended in November 2022, so you might see a stripped down version of the website. </p>
            <div className=' grid gap-7 mb-[48px] lg:grid-cols-2'>
                <MeasurementCom
                    count='149,724'
                    subject='clicks'
                    body='Loan calculator had the most clicks on English and French pages'
                />
                 <MeasurementCom
                    count='91,174'
                    subject='clicks'
                    body='The main “apply now” button had the 2nd most clicks'
                />
                 <MeasurementCom
                    count='7.10%'
                    subject='Bounce rate'
                    body='The page’s bounce rate is lower than the Canada Post avg. of 28.50%'
                />
                 <MeasurementCom
                    count='2:28 mins'
                    subject='Avg. time spent per visit'
                    body='Time spent on the page is lower than the Canada Post avg. of 4:09 mins.'
                />
            </div>
            <div className=' '>
                <h1 className=' text-[#160404] font-[600] mb-3 lg:test-[39px] '>Next steps</h1>
                <p className='  text-[16px] mb-3 font-[400] text-[#333333]'>Once loan applications resume, more qualitative research should be done to find pain points users are facing while using the page and its tools. </p>
            </div>
        </div>
    </div>
  )
}

export default Measurement