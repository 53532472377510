import React from 'react'

function Latest() {
  return (
    <div className=' mt-4'>
        <h1 className=' text-gray-400 text-3xl font-medium mb-4 leading-10'>Latest </h1>
        <div className=' mb-4'>
        <iframe className=' w-full' height="315" src="https://www.youtube.com/embed/lWpU2ZSk4WU?si=5_QEzlQrbzA7bM7n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <p className='text-zinc-400 mb-4 text-base font-normal  leading-normal'><span className=' text-zinc-400 text-base font-semibold  leading-normal'>Titled:</span>Divining unity</p>
        <p className='text-zinc-400 sm:mb-[64px] mb-[24px] md:mb-[86px] text-base font-normal  leading-normal'><span className=' text-zinc-400 text-base font-semibold  leading-normal'>Medium:</span> Acrylic and paint markers on cardboard, embellished with aged nuts and plastic beads.</p>
    </div>
  )
}

export default Latest