import React from 'react'
import B1 from '../../images/ss1.png'
import B2 from '../../images/ss2.png'
import B3 from '../../images/ss3.png'
import B4 from '../../images/ss4.png'
import B5 from '../../images/ss5.png'
import B6 from '../../images/ss6.png'
import B7 from '../../images/ss7.png'
import B8 from '../../images/ss8.png'
import ReuseableCard from '../Decentralized/ReuseableCard'
function Content() {
  return (
    <div className=''>
    <div className=' my-10'>
    <img src={B1} alt='section'/>
    <p className=' pt-4 font-normal text-[#7E7986] text-[14px]'> A photo of the banner installation in downtown Mississauga, Ontario</p>
    </div>
      <ReuseableCard
        image={B2}
        media={"Digital art"}
        title={"Urban matriarch"}
        linkText={""}
        content={"Urban matriarch depicts the responsibility of the single mother to raise and nurture her community."}
      /> 
       <ReuseableCard
        image={B3}
        media={"Digital art"}
        title={"Metaverse"}
        linkText={""}
        content={"Metaverse depicts the convergence of human consciousness, in the pursuit of connection."}
      /> 
        <ReuseableCard
        image={B4}
        media={"Digital art"}
        title={"Punk pop"}
        linkText={""}
        content={"Punk Pop is a reminder, while we are co-creating culture and exploring our diversity, to never forget that we are one."}
      /> 

        <ReuseableCard
        image={B5}
        title={"Hair salon"}
        media={"Digital art"}
        linkText={""}
        content={"Punk Pop is a reminder, while we are co-creating culture and exploring our diversity, to never forget that we are one."}
      /> 
       <ReuseableCard
        image={B6}
        title={"Summer trip"}
        media={"Digital art"}
        linkText={""}
        content={"Summer trip illustrates a meeting of warmth hunters. No ray or wave shall escape the army of bare skin and sunglasses."}
      /> 

        <ReuseableCard
        image={B7}
        title={"Generation now"}
        media={"Digital art"}
        linkText={""}
        content={"Generation now is a portrait of a generation of humans whose tomorrow is right now."}
      /> 
    <ReuseableCard
        image={B8}
        title={"Fake news"}
        media={"Digital art"}
        linkText={""}
        content={<p>
            Fake news is a commentary on the wide spread effects of disinformation on the collective psyche. 
                <br></br>  <br></br>
            It was not selected as part of  the six-banner series, but it was an important part of the creative process. 
        </p>}
      /> 
      
    </div>
  )
}

export default Content