import React,{useState} from 'react'
import ProjectCom from './ProjectCom'
import P1 from '../../images/p1l.png'
import P2 from '../../images/p2l.png'
import P3 from '../../images/art.png'
import P4 from '../../images/cSoonM.svg'
import Share from '../../images/sharee.svg'
import { db } from '../../firebase-config';
import { doc, onSnapshot } from 'firebase/firestore';
import ProjectCom1 from './ProjectCom1'
function Project() {
  const [likess, setLike] = useState(0)
  const [likes1, setLike1] = useState(0)
  const [likes2, setLike2] = useState(0)
  const [view, setView] = useState(0)
  const [view2, setView2] = useState(0)
  const [view3, setView3] = useState(0)

  const  docRef = doc(db, 'likes', 'GCZUnqSsRaLievViAZag')
  onSnapshot((docRef), (doc) =>{
    setLike(doc.data().likes)
  })
   
  const docRef2 = doc(db, 'likes', 'TXqHzXUux0h2uvQWM1PX')
  
  onSnapshot((docRef2), (doc) =>{
    setLike1(doc.data().blikes)
  })

  const docRef3 = doc(db, 'likes', '1O7BtNP9TX9jbHFupI2M')
  onSnapshot((docRef3), (doc) =>{
    setLike2(doc.data().cLikes)
  })
  const docRef4 = doc(db, 'likes', 'cRODsqChmRAH1pAju9FK')
  onSnapshot((docRef4), (doc) =>{
    setView(doc.data().views)
  })
  const docRef5 = doc(db, 'likes', 'e7ncbpN40ytpYLvRbD5a')
  onSnapshot((docRef5), (doc) =>{
    setView2(doc.data().bV)
  })
  const docRef6 = doc(db, 'likes', 'anpzYPO3SDv4a9o1PHUe')
  onSnapshot((docRef6), (doc) =>{
    setView3(doc.data().cView)
  })

  return (
    <div className=' pb-[48px] md:pb-[64px] pt-10 lg:pb-[96px]' id='project'>
        <h1 className=' text-[23px] text-primary-color pb-4 font-[600] lg:pl-4 md:text-[26px] lg:text-[32px]'>Projects</h1>
        <div className=' mb-[32px] md:mb-[24px] lg:mb-[48px]'>
      <ProjectCom
            con=
            {<div>
            <h1 className=' text-[#160404] font-[700] text-[16px] mb-2'>Coming soon!</h1>
            <p>Leading the design and development of an AI-enabled workplace mental health coach.</p>
            <a href={'https://drive.google.com/file/d/10xprXx0wKHMOFf9GOEDeE-4_Q7wjt0hV/view?usp=sharing'}  target='_blank' rel='noreferrer'> <p className=' pt-4'><span className=' text-[#7210EE]  underline '>View prototype demo</span> <img src={Share} className=' inline-block' alt='share'/></p></a>
            </div>}
            img={P4}
            bool={true}
            color=' bg-[#DBE7DE]'
            likes={likes2}
            view={view3}
            link={'https://drive.google.com/file/d/10xprXx0wKHMOFf9GOEDeE-4_Q7wjt0hV/view?usp=sharing'}
        />
      </div>
        <div className='mb-[32px] md:mb-[24px] lg:mb-[48px]'>
        <ProjectCom
            con='Cross-platform design and launch of Canada Post’s first Flexible loan product using findings from user research.'
            img={P1}
            color=' bg-[#D7E0EE]'
            likes={likess}
            view={view}
            link='/canadapoststudy'
        />
        </div>
        <div className='mb-[32px] md:mb-[24px] lg:mb-[48px]'>
        <ProjectCom1
            con={
            <div>
              <p>Experimentation on a Micro-funding mobile app solution for renewable energy adoption in Africa.</p>

              <a href={'https://www.figma.com/proto/rkZtCP93MSiXQRWQ5GqpwN?kind=proto&node-id=1-1386&page-id=0%3A1&scaling=scale-down&starting-point-node-id=1%3A2586&viewport=1566%2C371%2C0.19&fuid=875220968212876424'} target='_blank' className=' pt-[16px]' rel='noreferrer'>
              <p className=' pt-4'><span className=' text-[#7210EE] underline '>View prototype</span> <img src={Share} className=' inline-block' alt='share'/></p>
              </a>
            </div>
              }
            img={P2}
            color=' bg-[#D3ECEE]'
            likes={likes1}
            view={view2}
            link='/boosty'
        />
        </div>
       
      <div>
      <ProjectCom
            con=
            {<div>
            <p>Created the product vision and designed post-MVP experiments for the Aiconic™ NFT marketplace for AI art.</p>
            <a href={'https://www.figma.com/proto/weJoWprKB2jqRNvllbgZfr/Aiconic?page-id=511%3A1653&type=design&node-id=906-2037&viewport=-2555%2C696%2C0.13&t=PrW4xefwzpdTYTUN-8&scaling=min-zoom&starting-point-node-id=511%3A1668&hide-ui=1'} target='_blank' className=' pt-[16px]' rel='noreferrer'> <p className=' pt-4'><span className=' text-[#7210EE] underline '>View prototype</span> <img src={Share} className=' inline-block' alt='share'/></p></a>
            </div>}
            img={P3}
            bool={true}
            color=' bg-[#DBDBE7]'
            likes={0}
            view={0}
            link={'https://www.figma.com/proto/weJoWprKB2jqRNvllbgZfr/Aiconic?page-id=511%3A1653&type=design&node-id=906-2037&viewport=-2555%2C696%2C0.13&t=PrW4xefwzpdTYTUN-8&scaling=min-zoom&starting-point-node-id=511%3A1668&hide-ui=1'}
        />
      </div>
      
       
    </div>
  )
}

export default Project