import React, {useRef} from 'react'
import Face from '../Face'
import CSC from '../../images/dd4.png'
import CSC1 from '../../images/dd5.png'
import ND from '../../images/nd4.png'
import PD from '../../images/pd4.png'
import mediumZoom from 'medium-zoom'
import MagnifiedImage from '../MagnifiedImage'
import Sound from '../../Sounds/c7.mp3'
function CreditScore() {
    const zoom = useRef(mediumZoom())
  return (
    <div className=' sm:mx-10 py-[48px] sm:py-[64px] lg:py-[96px] mx-5 lg:mx-20 grid sm:grid-cols-12 '>
    <div className=' sm:col-span-4 '>
        <div className=' sm:flex sm:justify-center'>
        <div id='score'>
        <Face
            Sound={Sound}
            nextLink='#pat'
            prevLink='#easy'
        />
        </div>
        </div>
          
        </div>
    <div className=' sm:col-span-6'>
    <div>
    <h1 className=' lg:text-[39px] text-[26px] pt-3 mb-2 font-[600] text-[#160404]'>Credit score checker</h1>
        <div className=' mb-[48px] grid lg:grid-cols-2 rounded border border-[#C0C0C0]'>
            <div>
                <h1 className=' font-[600] text-[16px] text-[#160404] border-b border-b-[#C0C0C0] bg-[#F1EFF5] p-5'>Research findings</h1>
                <div className=' p-5 py-7'>
                    <p className=' pb-4'>Participants expected to be led to a different page to input their personal details, also indicating that they feel safer doing so. See “previous design” below the table.</p>
                    <img src={CSC} alt='return'/>
                </div>
            </div>
            <div>
                <h1 className=' font-[600] text-[16px] text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Design decisions</h1>
                <div className=' p-5 py-7'>
                    <p className=' pb-4'>Swapped the input fields with a content block that includes a text link to a personal verification page. See “new design” below the table.</p>
                    <img src={CSC1} alt='return'/>
                </div>
            </div>
            <div className=' border-t border-t-[#C0C0C0]'>
            <div className=' p-5 py-7'>
                    <p className=' pb-4'>6/25 participants (across personas) felt this content was afforded greater prominence on the page than needed.</p>
                   
                </div> 
            </div>
            <div className=' p-5 py-7 border-t border-t-[#C0C0C0]'>
                    <p className=' pb-4'>Simplified the content block by removing the prominent deep blue border and added a simple illustration to the layout.  </p>
                </div>
        </div>
        <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>New design</h1>
        <div className='mb-[48px]'>
        <MagnifiedImage
                  src={ND}
                    alt="New design"
                    zoom={zoom.current}
                    background="white"
            />
        </div>
        <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>Previous design</h1>
        <div className='mb-[48px]'>
        <MagnifiedImage
                  src={PD}
                    alt="Previous design"
                    zoom={zoom.current}
                    background="white"
            />
        </div>
    </div>
    </div>
</div>
  )
}

export default CreditScore