import React from 'react'
import Face from '../Face'
import Sk1 from './Sk1'
import Sk2 from './Sk2'
import Sk3 from './Sk3'
import Sk4 from './Sk4'
import Sound from '../../Sounds/B8.mp3'
function Sketching() {
  return (
    <div className=' px-7 sm:mx-10 lg:mx-20 bg-task-bg grid sm:grid-cols-12 gap-4 lg:py-[96px] sm:py-[48px] pt-[32px] pb-[48px]'>
      <div className=' sm:col-span-4'>
        <div className=' sm:flex sm:justify-center'>
        <div id='sketching'>
            <Face
              Sound={Sound}
              prevLink='#map'
              nextLink='#wireframes'
            />
        </div>
        </div>
      </div>
        
        <div className=' sm:col-span-7'>
            <h1 className='text-[26px] lg:text-[39px] font-[600] sm:w-[80%] text-[#160404] pb-3'>Sketching</h1>
            <p>After the user journey map was approved by the stakeholders, I sketched out the most important user flows. Following a presentation to stakeholders, a decision was made to explore the feasibility of the crypto payment feature suggested in the sketches.</p>
            <div>
            <div className=' hidden sm:block'>
            <Sk1/>
            <Sk2/>
            <Sk3/>
            <Sk4/>
            </div>
            
            </div>
           
        </div>
    </div>
  )
}

export default Sketching