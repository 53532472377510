import React from 'react'
import Main from '../../Components/Decentralized/Main'
import Navbar from '../../Components/Decentralized/Navbar'

function Decentralised() {
  return (
    <div className='   bg-[#1D1B21] h-full '>
           <Navbar/>
           <div>
            <Main/>
           </div>
    </div>
  )
}

export default Decentralised