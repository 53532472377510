import React from 'react'
import Face from '../Face'
import Sound from '../../Sounds/B2.mp3'
function Bussiness() {
  return (
    <div className=' px-7 sm:mx-10 lg:mx-20 bg-task-bg grid sm:grid-cols-12 gap-4 lg:py-[96px] sm:py-[48px] pt-[32px] pb-[48px]'>
             <div className=' sm:col-span-4'>
             <div className=' sm:flex sm:justify-center'>
             <div id='business'>
             <Face
              Sound={Sound}
              prevLink='#business'
              nextLink='#start'
             />
             </div>
             </div>
           
           
        </div>
        <div className=' sm:col-span-6'>
            <h1 className='text-[26px] lg:text-[39px] font-[600] sm:w-[80%] text-[#160404] pb-3'>Business goals</h1>
            <p className='text-[16px] font-[400] sm:w-[80%] text-[#333333] pb-3'>Stakeholders want their product to be more scalable, and still deliver on its vision of transitioning small businesses in Africa to sustainable energy.
                <br></br><br></br>
                I was tasked to explore a solution that can scale regardless of the cost of the energy hardware.
                <br></br><br></br>
                Also, the stakeholders wanted to verify if young adults are a viable target user for the product.</p>
        </div>
    </div>
  )
}

export default Bussiness