import React from 'react'
import Face from '../Face'
import Sound from '../../Sounds/B1.mp3'
function Intro() {
  return (
    <div className=' px-5 sm:mx-10 lg:mx-20 grid sm:grid-cols-12 gap-4 lg:py-[96px] sm:py-[48px] pt-[32px] pb-[48px]'>
        <div className=' sm:col-span-4 '>
        <div className=' sm:flex sm:justify-center'>
        <div id='into'>
        <Face
          Sound={Sound}
          prevLink='#'
          nextLink='#business'
        />
        </div>
        </div>
          
        </div>
        <div className=' sm:col-span-6 '>
            <h1 className='text-[20px] font-[600] sm:w-[80%] text-[#333333] pb-3'>Boosty is a digital solution that enables micro-funding of renewable energy for small businesses in African communities.</h1>
            <p className='text-[16px] font-[400] sm:w-[80%] text-[#333333] pb-3'>Boosty has pivoted twice already. It started as a smart solar powered kiosk, but it was too expensive to produce and too expensive for the target users to buy.
                <br></br><br></br>
            Following research findings, the team pivoted and designed a more portable and affordable hardware. This too was expensive for the user, and difficult to scale.</p>
        </div>
    </div>
  )
}

export default Intro