import React from 'react'
import A from '../../images/a1.png'
import A1 from '../../images/aM1.png'
function Topsec() {
  return (
    <div className=' lg:mx-20 pt-32 p-6 rounded flex-col-reverse sm:flex-row sm:mx-10 bg-[#F6EEE2] flex gap-5'>
        <div className='basic-1/2 sm:basis-2/5 flex justify-center sm:px-8 flex-col'>
            <h1 className=' text-[#160404] sm:text-[32px] text-[25px] mb-3 lg:text-[39px] font-[600]'>I am working on a new case study!</h1>
            <p className=' text-[#333333] text-[16px] font-[400]'>In the meantime, feel free to check out the existing ones and let me know what you think. Your feedback helps.</p>
        </div>
        <div className='basic-1/2 md:basis-3/5'>
            <img src={A} className=' hidden mx-auto md:block' alt='Cover'/>
            <img src={A1} className=' md:hidden sm:flex sm:justify-center sm:flex-row block' alt='Cover'/>
        </div>
    </div>
  )
}

export default Topsec