
function ButtonCom({onClick, name}) {
  return (
    
     <>
     <button 
      className=' bg-[#110F14] px-[14px] hover:border-[#7210EE] hover:text-[#7210EE] py-[8px] border-[#110F14] border-2 rounded-[99px] text-[#F9F7FC]'
      onClick={onClick}
     >{name} </button>   
    </>
    
  )
}

export default ButtonCom