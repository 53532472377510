import React from 'react'

function ProcessCom({body}) {
  return (
    <div className='bg-[#E3E0EA] w-full p-5 flex flex-col justify-start'>
            <p className=' text-center text-[14px] font-[400] text-[#160404]'>{body}</p>
    </div>
  )
}

export default ProcessCom