import { BrowserRouter, Route, Routes } from "react-router-dom";
import Boosty from "./Pages/Boosty";
import CPS from "./Pages/CanadaPostStudy";
import Home from "./Pages/Home";
import FeedBack from './Pages/Feedback'
import Scrolltotop from "./Components/Scrolltotop";
import Aspire from "./Pages/Aspired";
import Arts from "./Pages/Art";
import Decentralised from "./Pages/Decentralised";
import Summer from "./Pages/Summer";
import Thousand from "./Pages/Thousand";
import Dreaming from "./Pages/Dreaming";
import Time from "./Pages/Time";
import Forex from "./Pages/Forex";
import Wall from "./Pages/Wall";
import Cv from "./Pages/Cv";

function App() {
  return (
    
    <div >
      <BrowserRouter>
      <Scrolltotop/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/canadapoststudy" element={<CPS/>}/>
          <Route path="/boosty" element={<Boosty/>}/>
          <Route path="/feedback" element={<FeedBack/>}/>
          <Route path="/aspire" element={<Aspire/>}/>
          <Route path="/art" element={<Arts/>}/>
          <Route path="*" element={<Home/>}/>
          <Route path="/dcentralizm" element={<Decentralised/>}/>
          <Route path="/summer" element={<Summer/>}/>
          <Route path="/thousand" element={<Thousand/>}/>
          <Route path="/dreaming" element={<Dreaming/>}/>
          <Route path="/time" element={<Time/>}/>
          <Route path="/forex" element={<Forex/>}/>
          <Route path="/wall" element={<Wall/>}/>
          <Route path="/cv" element={<Cv/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
