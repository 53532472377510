import React from 'react'
import { Link } from 'react-router-dom'
function Collection() {
  return (
    <div className=' p-3 px-5 ' id='collection'>
        <h1 className=' text-[32px] font-[500] mb-2 text-[#BAB3C3]'>Collections</h1>
        <div className=' grid lg:grid-cols-2 gap-6 '>
          <Link to={'/summer'}>
          <div className=' hover:border-4 rounded hover:border-[#A157FF]  relative one'>
           <div className=' inset-center'>
           <p className='  text-[#9C98A3] font-[400] text-center  text-[16px] '>Summer connections</p>
           </div> 
            </div>
          </Link>
          <Link to={'/thousand'}>
            <div className=' relative hover:border-4 hover:border-[#A157FF] rounded two'>
            <p className=' inset-center text-[#9C98A3] font-[400] text-[16px] '>A thousand lines</p>
            </div>
            </Link>
            <Link to={'/time'}>
            <div className=' relative hover:border-4 hover:border-[#A157FF] rounded three'>
            <p className=' inset-center text-[#9C98A3] font-[400] text-[16px] '>Time doors</p>
            </div>
            </Link>
            <Link to={'/dreaming'}>
            <div className=' relative hover:border-4 hover:border-[#A157FF] rounded four'>
            <p className=' inset-center text-[#9C98A3] font-[400] text-[16px] '>Dreaming in ink</p>
            </div>
            </Link>
            <Link to={'/forex'}>
            <div className=' relative hover:border-4 hover:border-[#A157FF] rounded five'>
            <p className=' inset-center text-[#9C98A3] font-[400] text-[16px] '>Forex</p>
            </div>
            </Link>
            <Link to={'/wall'}>
            <div className=' relative hover:border-4 hover:border-[#A157FF] rounded six'>
            <p className=' inset-center text-[#9C98A3] font-[400] text-[16px] '>Mural projects</p>
            </div>
           </Link>
        </div>
    </div>
  )
}

export default Collection