import React, { useRef } from 'react'
import Face from '../Face'
import R1 from '../../images/rrf.png'
import R2 from '../../images/dd7.png'
import ND from '../../images/nd5.png'
import PD from '../../images/pd5.png'
import mediumZoom from 'medium-zoom'
import MagnifiedImage from '../MagnifiedImage'
import Sound from '../../Sounds/c8.mp3'
function Patnership() {
    const zoom = useRef(mediumZoom())
  return (
    <div className=' bg-task-bg  px-5 md:mx-10 py-[48px] sm:py-[64px] lg:py-[96px] lg:mx-20 grid sm:grid-cols-12 '>
         <div className=' sm:col-span-4 '>
        <div className=' sm:flex sm:justify-center'>
        <div id='pat'>
        <Face
            Sound={Sound}
            nextLink='#fin'
            prevLink='#score'
        />
        </div>
        </div>
          
        </div>
        <div className=' sm:col-span-6'>
        <h1 className=' lg:text-[39px] text-[26px] sm:py-0 py-3 mb-2 font-[600] text-[#160404]'>Partnership banner</h1>
            <div className=' mb-[48px] grid lg:grid-cols-2 rounded border border-[#C0C0C0]'>
                <div>
                    <h1 className=' font-[600] text-[16px] text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Research findings</h1>
                    <div className=' p-5 py-7'>
                        <p className=' pb-4'>The black, co-branded banner was perceived as the bottom of the page by 10/25 participants, halting exploration of the remainder of the page</p>
                        <img src={R1} alt='return'/>
                    </div>
                </div>
                <div>
                    <h1 className=' font-[600] text-[16px] text-[#160404] border-b borderr-b-[[#C0C0C0] bg-[#F1EFF5] p-5'>Design decisions</h1>
                    <div className=' p-5 py-7'>
                        <p className=' pb-4'>I changed the colour of the banner to a light grey and reduced the text width so it is easier to read and less like a footer.</p>
                        <img src={R2} alt='return'/>
                    </div>
                </div>
            </div>
            <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>New design</h1>
            <div className='mb-[48px]'>
            <MagnifiedImage
                  src={ND}
                    alt="New design"
                    zoom={zoom.current}
                    background="white"
            />
            </div>
            <h1 className=' lg:text-[16px] mb-2 font-[600] text-[#160404]'>Previous design</h1>
            <div className='mb-[48px]'>
            <MagnifiedImage
                  src={PD}
                    alt="Previous design"
                    zoom={zoom.current}
                    background="white"
            />
            </div>
        </div>
    </div>
  )
}

export default Patnership