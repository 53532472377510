import React from 'react'
import Navbar from '../../Components/Decentralized/Navbar'
import Main from '../../Components/Time/Main'

function Time() {
  return (
    <div className='   bg-[#1D1B21] h-full '>
    <Navbar/>
    <div>
     <Main/>
    </div>
</div>
  )
}

export default Time