import React from 'react'
import { Link } from 'react-router-dom'
import Home from '../../images/homme.svg'
function Navbar() {
  return (
    <div className='   fixed bg-[#1D1B21] z-50  px-5 sm:px-10 lg:px-10  w-full'>
    <div className='  p-3 flex  border-b border-b-[#7E7986] justify-between'>
    <div>
            <Link to='/art'>
            <img src={Home} alt='Home'/>
            </Link>
          
        </div>
        <div className=' flex pt-3 gap-5'>

        <Link to={'/cv'} className=' hidden md:block text-[#9E99A5] text-[14px]'>CV</Link>
            <Link to={'/dcentralizm'} className=' text-[#A157FF] text-[14px]'>Dcentralizm</Link>
        </div>
    </div>
      
    </div>
  )
}

export default Navbar