import React from 'react'
import Back from '../Back'
function Navbar() {
  return (
    <div className=' md:mx-10 lg:mx-20 mx-5 border-b border-b-gray-200 bg-white p-5 flex justify-between'>
        <div>
            <Back/>
          
        </div>
        
    </div>
  )
}

export default Navbar