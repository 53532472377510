import React from 'react'

function SliderCom({img, name, body, title}) {
  return (
    <div className=' p-4 mx-3 grid h-[360px] overflow-y-auto bg-[#EFF0F2] rounded'>
    <div>
    <img src={img} alt='users'/>
        <p className=' pt-3 text-[20px] text-[#160404] font-[600]'>{name}</p>
        <p className=' pt-1 text-[14px] text-[#160404] font-[400]'>{title}</p>
        <p className=' py-3 text-[16px] text-[#160404] font-[400]'>{body}</p>
    </div>
       
    </div>
  )
}

export default SliderCom