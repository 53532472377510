import React from 'react'
import D1 from '../../images/dr1.png'
import D2 from '../../images/dr2.png'
import D3 from '../../images/dr3.png'
import D4 from '../../images/dr4.png'
import ReuseableCard from '../Decentralized/ReuseableCard'
function Content() {
  return (
    <div>
        <ReuseableCard
            image={D1}
            title={'Succubus'}
            media={"Ink on paper, digitally animated."}
            content={"To every sweet sensation that I followed into the murky depths, I say thank you. Without you, I would not have known my desires, and I would not have found the way home."}
            link={"https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335250416518299653/"}
            linkText={"View NFT"}
        />
         <ReuseableCard
            image={D2}
            title={'Inception'}
            media={"Ink on paper, digitally animated."}
            content={"Where the dream begins, is where reality collapses."}
            link={"https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335249317006671877/"}
            linkText={"View NFT"}
        />
        <ReuseableCard
            image={D3}
            title={'Dreaming in ink'}
            media={"Coloured ink on paper"}
            content={"This is the first drawing of the series. The first of my fleeting daydreams and meditations. This art form allows me to let go of anxiety and surrender to the present moment."}
            //link={"https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335249317006671877/"}
            linkText={""}
        />
         <ReuseableCard
            image={D4}
            title={'Abundance'}
            media={"Coloured ink on paper"}
            content={"Unravelling dreams and birthing an Equinox."}
            //link={"https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105204264793686757412392040086980789492115727908078666483560335249317006671877/"}
            linkText={""}
        />
    </div>
  )
}

export default Content