import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from '../../images/homme.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
function Navbar() {
  const [open, setOpen] = useState(false)
  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };
  const handleMouseLeave3 = () => {
    setOpen(!open);
  };
  const [open2, setOpen2] = useState(false)
    const handleMouseEnter2 = () => {
      setOpen2(true);
    };
  
    const handleMouseLeave2 = () => {
      setOpen2(false);
    };
  return (
    <div className='  fixed bg-[#1D1B21] z-50  px-5 sm:px-10 lg:px-10  w-full '>
    <div className='  p-3 flex  border-b border-b-[#3E3A44] justify-between'>
        <div>
        <Link to='/art'>
            <img src={Logo} alt='Home'/>
        </Link>
        </div>



     <div className=' block md:hidden'>
     <div
                          className="relative inline-block"
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        onClick={handleMouseLeave3}
                        >
                        <button className=" text-[#9E99A5]  font-[500px] text-[14px] pt-4 flex px-4 py-2 rounded">
                        Menu  {open ? <KeyboardArrowDownIcon/> :<KeyboardArrowUpIcon/>}
                          </button>
                          
                          {open && 
                            <div className="absolute xl:w-[190px] left-0 top-full bg-[#3F3A48]    shadow-md">
                            <NavLink className={({isActive}) =>{
                            return( '  block py-3 px-2 font-[400] text-[14px]' + (
                              !isActive ? ' hover:text-[#A8A2AF] text-[#A8A2AF] border-b border-[#AFAFAF] hover:bg-[#110F14] ' : ' text-[#A157FF]  bg-[#110F14]  '
                            ))
                            }}   to='/summer'>
                               Summer connections
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' block py-3 px-2 font-[400] text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF]  hover:text-[#A8A2AF] border-b border-[#AFAFAF] hover:bg-[#110F14] ' : ' text-[#A157FF] bg-[#110F14]  '
                            ))
                            }}   to='/thousand'>
                              A thousand lines
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( '  block py-3 px-2 font-[400] text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF] hover:text-[#A8A2AF] border-b border-[#AFAFAF] hover:bg-[#110F14] ' : ' text-[#A157FF] bg-[#110F14]  '
                            ))
                            }}   to='/dreaming'>
                             Dreaming in Ink
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( '  block py-3 px-2 font-[400] text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF] hover:text-[#A8A2AF] border-b border-[#AFAFAF] hover:bg-[#110F14] ' : 'text-[#A157FF] bg-[#110F14]  '
                            ))
                            }}   to='/time'>
                            Time doors
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( '  block py-3 px-2 font-[400] text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF] hover:text-[#A8A2AF] border-b border-[#AFAFAF] hover:bg-[#110F14] ' : 'text-[#A157FF] bg-[#110F14]  '
                            ))
                            }}   to='/wall'>
                            Murals
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( '  block py-3 px-2 font-[400] text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF] hover:text-[#A8A2AF] border-b border-[#AFAFAF] hover:bg-[#110F14] ' : 'text-[#A157FF] bg-[#110F14]  '
                            ))
                            }}   to='/forex'>
                            Forex
                                </NavLink>
                                <NavLink className={({isActive}) =>{
                            return( ' block py-3 px-2 font-[400] text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF]  hover:text-[#A8A2AF] border-b border-[#AFAFAF] hover:bg-[#110F14] ' : 'text-[#A157FF] bg-[#110F14]  '
                            ))
                            }}   to='/CV'>
                           CV
                                </NavLink>
                            </div>
                          }
      </div>
     </div>
     <div className=' md:flex gap-[48px] hidden  pt-2 justify-between'>
                <NavLink className={({isActive}) =>{
                            return( '  block my-3 font-[400] text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF] hover:text-[#A157FF] hover:font-normal  ' : ' text-[#A157FF]   '
                            ))
                            }}   to='/summer'>
                               Summer connections
                    </NavLink>
                        <div
                          className="relative inline-block"
                          onMouseEnter={handleMouseEnter2}
                          onMouseLeave={handleMouseLeave2}
                        >
                        <button className=" text-[#9E99A5] pt-3  font-[500px] text-[14px] flex px-4  rounded">
                        NFT collections {open2 ? <KeyboardArrowDownIcon/> :<KeyboardArrowUpIcon/>}
                          </button>
                          
                          {open2 && 
                            <div className="absolute rounded left-0 top-full bg-[#3F3A48]   shadow-md">
                            <NavLink className={({isActive}) =>{
                            return( '  block font-[400] border-b-2 border-[#A8A2AF] px-3 py-3 text-center text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF] hover:text-[#A157FF] hover:font-normal' : ' bg-[#110F14] text-[#A157FF]   '
                            ))
                            }}   to='/thousand'>
                               A thousand lines
                                 </NavLink>
                                 <NavLink className={({isActive}) =>{
                             return( '  block font-[400] px-3  text-center py-3 text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF] hover:text-[#A157FF] hover:font-normal' : ' bg-[#110F14] text-[#A157FF]   '
                            ))
                            }}   to='/dreaming'>
                              Dreaming in Ink
                             </NavLink>
                                
                            </div>
                          }
                        </div>
        <NavLink className={({isActive}) =>{
                            return( '  block my-3 font-[400] text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF] hover:text-[#A157FF] hover:font-normal' : ' text-[#A157FF]   '
                            ))
                            }}   to='/time'>
                              Time doors
        </NavLink>
        <NavLink className={({isActive}) =>{
                            return( '  block my-3 font-[400] text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF] hover:text-[#A157FF] hover:font-normal ' : ' text-[#A157FF]'
                            ))
                            }}   to='/wall'>
                             Murals
        </NavLink>
        <NavLink className={({isActive}) =>{
                            return( '  block my-3 font-[400] text-[14px]' + (
                              !isActive ? ' text-[#A8A2AF] hover:text-[#A157FF] hover:font-normal' : ' text-[#A157FF] '
                            ))
                            }}   to='/forex'>
                            Forex
        </NavLink>
     </div>  
     <div className=' flex gap-6 pt-4'>
      <Link to={'/CV'} className=' hidden hover:text-[#A157FF] md:block text-[#9E99A5] text-[14px]'>CV</Link>
      <a href='#contact' className=' hover:text-[#A157FF] text-[14px] text-[#9E99A5] block'>Contact me</a>
     </div>
     </div>
    </div>
  )
}

export default Navbar