import React from 'react'

function StepCom({img, body}) {
  return (
    <div className=' bg-[#E3E0EA] rounded w-full p-5  flex justify-center '>
        <div>
            <img src={img} className=' mx-auto' alt='count'/>
            <p className=' text-center text-[14px] py-3 font-[400] text-[#160404]'>{body}</p>
        </div>
    </div>
  )
}

export default StepCom