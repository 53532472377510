import React from 'react'
import { Link } from 'react-router-dom'
import Home from '../../images/home.svg'
function Navbar() {
  return (

    <div className=' fixed bg-white  z-50  px-5 sm:px-10 lg:px-20  w-full'>
       <div className=' p-5 flex  border-b border-b-gray-300 justify-between'>
        <div>
            <Link to='/'>
            <img src={Home} alt='Home'/>
            </Link>
          
        </div>
        <div className=' flex gap-5'>
            <a href='#prototype' className=' hover:text-[#7210EE] text-[14px] hidden sm:block'>Prototype</a>
            <a href='#prototypes' className=' hover:text-[#7210EE] text-[14px] sm:hidden block'>Prototype</a>
            <a href='#contact' className='hover:text-[#7210EE] text-[14px]'>Contact me</a>
        </div>
    </div>
    </div>
   
  )
}

export default Navbar