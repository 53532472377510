import React from 'react'
import D1 from '../../images/ti1.png'
import D2 from '../../images/ti2.png'
import D3 from '../../images/ti3.png'
import D4 from '../../images/ti4.png'
import D5 from '../../images/ti5.png'
import D6 from '../../images/ti6.png'
import ReuseableCard from '../Decentralized/ReuseableCard'
function Content() {
  return (
    <div className=' pt-6'>
        <ReuseableCard
            image={D1}
            title={'44'}
            media={"Acrylic on wooden door panels, brass keys and key locks."}
            content={"The artwork reflects the interconnected nature of human perception."}
            linkText={""}
        />
         <ReuseableCard
            image={D2}
            title={'Synchronicities'}
            media={"Acrylic on wooden door panels, brass keys and key locks."}
            content={"It reflects my relationship with the concepts of time and space. The key locks represent Opportunity and the clock handles represent Time."}
            linkText={""}
        />
         <ReuseableCard
            image={D3}
            title={'Market square'}
            media={"Acrylic on wooden door panels, brass keys and key locks."}
            content={"This 2-in-1 art piece depicts life as a marketplace where an individual has to constantly negotiate for his or her own attention with peers, authorities, family, and self."}
            linkText={""}
        />
        <div className=' mt-12'>
        <ReuseableCard
            image={D4}
            header={"Older experiments"}
            title={'Small door'}
            media={"Acrylic on wooden door panels, brass keys and key locks."}
            content={"This piece remains one of the smallest version of the time doors. It explores the approach of creating a door within a door, adding an extra dimension."}
            linkText={""}
        />
         <ReuseableCard
            image={D5}
            title={'Untitled'}
            media={"Acrylic on wooden door panels, brass keys and key locks."}
            content={<p>
                This is my earliest experiment creating with door panels. It was also the first time I used the old-school brass locks and keys to represent eyes in my art compositions. 
                <br></br><br></br>
                Creating this piece connected me with a lot of unresolved emotions and gave me some insights into how I have been living and how I could possibly change my perception ...change my life.
            </p>}
            linkText={""}
        />
        <ReuseableCard
            image={D6}
            title={"Ol’ door"}
            media={"Acrylic on wooden door panels, brass keys and key locks."}
            content={"The original door used for this piece was gotten from a demolition site of a very old building. It has the structure of window doors from the 1970s and 1980s western Nigeria. I love how its features looked like a traditional African masks. I enjoyed applying acrylic colour and watching it transform in meaning and purpose."}
            linkText={""}
        />
        </div>
    </div>
  )
}

export default Content