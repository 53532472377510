import React, { useState } from 'react'
import Cps from '../../images/cps.png'
import Cpss from '../../images/medC.png'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function Topsec() {
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>

     <div className='hidden   mx-5 sm:mx-10 lg:mx-20 sm:grid sm:grid-cols-2 gap-4 bg-[#D7E0EE] pt-32 px-8 py-14 rounded'>
    <div className='md:flex md:ml-7  md:flex-col md:justify-center'>
       <div className=' sm:w-full lg:w-[80%]  '>
        <div className=' grid grid-cols-2 border-y py-4 border-y-[#B6875E] gap-4 md:w-[80%] '>
            <p className=' font-[600] text-[16px] text-[#160404]'>Client</p>
            <p>Canada Post</p>
            <p className=' font-[600] text-[16px] text-[#160404]'>Sector</p>
            <p>Retail finance</p>
            <p className=' font-[600] text-[16px] text-[#160404]'>My Role</p>
            <p>Product design</p>
            <p className=' font-[600] text-[16px] text-[#160404]'>Outcome</p>
            <p>32% conversion rate</p>
            <p className=' font-[600] text-[16px] text-[#160404]'>Project Time</p>
            <p>4 months</p>
        </div>
       </div> 
       <p className=' pt-[24px] text-[#B6875E]'><QueryBuilderIcon/> Relax, It’s only a 6 minute read</p>
    </div>
    <div className=' flex justify-center flex-col'>
    <div>
    <img src={Cps} className=' hidden   lg:block' alt='Canadapoststudy'/>
        <img src={Cpss} className=' lg:hidden    sm:block' alt='Canadapoststudy'/>
    </div>
      
    </div>
    </div>
    <div className='mx-5 block sm:hidden pt-20'>
    <div className=' bg-[#D7E0EE] rounded  p-3'>
      <Accordion
      expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
      elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
          backgroundColor: "#D7E0EE"}}
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
          backgroundColor: "#D7E0EE"
        }}
        >
          <Typography>
          <img src={Cps} className=' lg:hidden   mx-auto sm:block' alt='Canadapoststudy'/>
          </Typography>
        </AccordionDetails>
      </Accordion>
    
      <div className=' '>
        <div className=' grid grid-cols-2 border-y py-4 rounded border-y-[#B6875E] gap-4  '>
            <p className=' font-[600] text-[16px] text-[#160404]'>Client</p>
            <p>Canada Post</p>
            <p className=' font-[600] text-[16px] text-[#160404]'>Sector</p>
            <p>Retail finance</p>
            <p className=' font-[600] text-[16px] text-[#160404]'>My Role</p>
            <p>Product design</p>
            <p className=' font-[600] text-[16px] text-[#160404]'>Project Time</p>
            <p>4 months</p>
        </div>
        <p className=' pt-[24px] pb-4 text-[#B6875E]'><QueryBuilderIcon/> Relax, It’s only a 6 minute read</p>
       </div> 
    </div>
    </div>
   
    </>
   
  )
}

export default Topsec