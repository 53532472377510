import React from 'react'
import Face from '../Face'
import I1 from '../../images/ms1.png'
import I2 from '../../images/ms2.png'
import I3 from '../../images/ms3.png'
import I4 from '../../images/ms4.png'
import Sound from '../../Sounds/c10.mp3'
function Imagesource() {
  return (
    <div className='sm:mx-10 bg-task-bg mx-5 py-[48px] sm:py-[64px] lg:py-[96px] lg:mx-20 grid md:grid-cols-12'>
        <div className=' sm:col-span-4 '>
        <div className=' sm:flex  mx-5 sm:justify-center'>
        <div id='image'>
        <Face
          Sound={Sound}
          nextLink='#measure'
          prevLink='#fin'
        />
        </div>
        </div>
          
        </div>
        <div className=' mx-5 sm:col-span-6'>
            <h1 className=' lg:text-[39px] text-[26px] py-3 font-[600]  text-[#160404] mb-3 '>Image sourcing</h1>
            <p className=' text-[16px] font-normal mb-2 text-[#333333]'>Images were sourced by following Canada Post rebrand guidelines. Images had to show diversity and real human stories.</p>
            <div className=' grid gap-4   md:grid-cols-2'>
                <img src={I1} className='w-full rounded' alt='imagesource'/>
                <img src={I2} className='w-full rounded' alt='imagesource'/>
                <img src={I3} className='w-full rounded' alt='imagesource'/>
                <img src={I4} className='w-full rounded' alt='imagesource'/>
            </div>
        </div>
    </div>
  )
}

export default Imagesource