import React from 'react'
import Face from '../Face'
import StepCom from './StepCom'
import One from '../../images/1.png'
import Two from '../../images/2.png'
import Next from '../../images/nexxt.png'
import Three from '../../images/3.png'
import Sound from '../../Sounds/c3.mp3'
function Steps() {
  return (
    <div className='sm:mx-10 mx-5 lg:mx-20 py-[48px] sm:py-[64px] lg:py-[96px] grid sm:grid-cols-12 gap-4 lg:px-6 '>
        <div className=' sm:col-span-4 '>
        <div className=' sm:flex sm:justify-center'>
        <div id='step'>
        <Face
            Sound={Sound}
            prevLink='#task'
            nextLink='#return'
        />
        </div>
        </div>
          
        </div>
        <div className=' sm:col-span-6'>
            <h1 className=' lg:text-[39px] mb-2 font-[600] text-[#160404]'>The steps</h1>

            <div className=' lg:flex hidden pb-[24px] gap-4'>
                <StepCom
                    img={One}
                    body='Redesign page using findings from usability test'
                />
                <div className=' flex flex-col   justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <StepCom
                    img={Two}
                    body='Production'
                />
                <div className=' flex flex-col   justify-center'>
                    <img src={Next} className=' w-16' alt='Next'/>
                </div>
                <StepCom
                    img={Three}
                    body='Measure performance'
                />
            </div>
            <div className=' flex gap-4 lg:hidden'>
            <StepCom
                    img={One}
                    body='Redesign page using findings from usability test'
                />
                <div className=' flex flex-col   justify-center'>
                    <img src={Next} className=' w-12' alt='Next'/>
                </div>
                <StepCom
                    img={Two}
                    body='Production'
                />
            </div>
            <div className=' grid grid-cols-2 mb-4 mt-4 gap-10 lg:hidden'>
                <StepCom
                    img={Three}
                    body='Measure performance'
                />
                
            </div>
            <div>
                <h1 className=' text-[16px] font-[500] text-[#160404] pb-3'>Constraints:</h1>
                <div className=' pb-[24px]'>
                    <ul>
                        <li>&#x2022; Contending with two design systems.  </li>
                        <li>&#x2022; Working with a new design system that is still under construction.</li>
                        <li>&#x2022; Adjusting to the new scrum team and company processes.</li>
                    </ul>
                </div>
                <p className=' text-[16px] font-[600] text-[#333333]'>For the rest of the presentation, I will focus on the pain points and how user research findings influenced the design of some key features.</p>
            </div>
        </div>
    </div>
  )
}

export default Steps