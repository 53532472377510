import React from 'react'
import UserSum from './UserSum'

function UsersSummary() {
  return (
    <div className='   sm:grid hidden sm:grid-cols-12  pt-[48px] sm:pt-[64px] lg:pt-[96px] gap-6 '>
        <div className=' sm:col-span-4'></div>
        <div className=' sm:col-span-6'>
            <UserSum/>
        </div>
    </div>
  )
}

export default UsersSummary