import React from 'react'

function Bio() {
  return (
    <div className=' pt-[48px] px-5 sm:pt-[96px] ' id='bio'>
        <h1 className=' text-[26px] font-[500] text-[#BAB3C3] mb-3 sm:text-[32px]'>Bio</h1>
        <p className=' text-[#9E99A5] font-[400] sm:text-[16px]'>
        My name is Aimua, but most people know me as Ai. I create art driven by my interests in identity, perception, and the human experience of self. I strive to make my artistic endeavors relevant to society at large because, to me, art is a crucial means for exploring consciousness and our place in the world.
<br></br><br></br>
I was born in Canada, but I have spent most of my adult life in Nigeria. I began expressing my artistic tendencies at the young age of three, using twigs and broomsticks to draw endless patterns all over Grandma’s backyard.
<br></br><br></br>
My education in classical European figurative painting laid the foundation for my artistic journey. However, my latest works depart from the realism-centric training and move towards a more personalized narrative. This approach allows me the freedom to choose from a broader pool of aesthetic systems, enriching my creative expression.
<br></br><br></br>
My creative works span various mediums, including sculpture, user experience, product design, illustrations, painting, poetry, mixed media, and installation. I don't limit myself to the confines of museums and galleries; instead, my practice aims to explore the broader public sphere through brand engagements, interventions in digital space, social challenges, and issues of sustainability.
<br></br><br></br>
After a successful career in creative advertising and experiential marketing, I made the decision to focus on my art and product design practice. For me, it's a way to solve interesting problems in the public sphere and make a meaningful impact. Currently, I am working on plans to develop an innovation and art studio in Ontario, Canada, with a focus on harnessing the unique perspective of immigrants to solve problems.
            </p>
    </div>
  )
}

export default Bio