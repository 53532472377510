import React from 'react'
import Face from '../Face'
import W1 from '../../images/w1.png'
import W2 from '../../images/w2.png'
import W3 from '../../images/w3.png'
import W4 from '../../images/w4.png'
import W5 from '../../images/w5.png'
import W6 from '../../images/w6.png'
import mediumZoom from 'medium-zoom'
import MagnifiedImage from '../MagnifiedImage'
import Sound from '../../Sounds/B9.mp3'
function Wireframe() {
  const zoom = React.useRef(mediumZoom())
  return (
    <div className=' px-7 sm:mx-10 lg:mx-20 grid sm:grid-cols-12 gap-4 lg:py-[96px] sm:py-[48px] pt-[32px] pb-[48px]'>
         <div className=' sm:col-span-4'>
        <div className=' sm:flex sm:justify-center'>
        <div id='wireframes'>
            <Face 
              Sound={Sound}
              prevLink='#sketching'
              nextLink='#fidelity'
            />
        </div>
        </div>
      </div>
        <div className=' sm:col-span-6'>
        <h1 className='text-[26px] lg:text-[39px] font-[600] sm:w-[80%] text-[#160404] pb-3'>Wireframes</h1>
        <p className='text-[16px] font-[400] sm:w-[80%] text-[#333333] pb-6'> In order to meet up with strict project deadlines, I decided to create only a few wireframe designs. I focused on the most important screens get an idea of how I could lay out the core elements.</p>
            <div className=' grid md:grid-cols-3 gap-4 grid-cols-2'>
            <MagnifiedImage
                  src={W1}
                    alt="Wireframe image"
                    zoom={zoom.current}
                    background="white"
            />
            <MagnifiedImage
                  src={W2}
                    alt="Wireframe image"
                    zoom={zoom.current}
                    background="white"
            />
            <MagnifiedImage
                  src={W3}
                    alt="Wireframe image"
                    zoom={zoom.current}
                    background="white"
            />
            <MagnifiedImage
                  src={W4}
                    alt="Wireframe image"
                    zoom={zoom.current}
                    background="white"
            />
            <MagnifiedImage
                  src={W5}
                    alt="Wireframe image"
                    zoom={zoom.current}
                    background="white"
            />
            <MagnifiedImage
                  src={W6}
                    alt="Wireframe image"
                    zoom={zoom.current}
                    background="white"
            />
           
            </div>
        </div>
    </div>
  )
}

export default Wireframe