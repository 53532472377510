import React from 'react'
import Icoon1 from '../../images/icc1.svg'
import Iconn2 from '../../images/icc2.svg'
import Iconn3 from '../../images/icc3.svg'
import BB from '../../images/dB.png'
import BS from '../../images/ds.png'

import Latest from './Latest'
import Public from './Public'
import Content from './Content'
import Contactus from '../art/Contact'

function Main() {
  return (
    <div className= ' mx-5 sm:mx-20 sm:pt-5  h-full'>
        
    <div className='sm:fixed px-5 sm:top-[6%] pt-5 sm:mt-12 sm:left-0 '>
    <div className= ' sm:flex sm:w-1/2' >
        <div className=' sm:px-20'>
        <div className=' flex md:block pt-5 sm:pt-0 justify-between sm:justify-center '>
        <div className=' pt-20 sm:pt-0'>
      
        <div className=' flex sm:block gap-4'>
        <div>
        <img src={BB}  className='mx-auto hidden sm:block' alt='head'/> 
        <img src={BS}  className='mx-auto sm:hidden block' alt='head'/> 
        </div>
        <div className=' flex sm:hidden flex-col justify-center gap-4'>
            <div className=' flex gap-4'>
            <a href='https://linktr.ee/dcentralizm' target='_blank' rel='noreferrer'><img src={Icoon1} alt='Icon'/> </a>  
              <div>  <a href='#contact'> <img src={Iconn2} alt='Icon'/></a></div>
               <a href='https://medium.com/@aithelight/dcentralizm-divining-unity-38a4a2a9627c?source=friends_link&sk=70dda116cf96e73efd993ee7344d9bf9' target='_blank' rel='noreferrer'> <img src={Iconn3} alt='Icon'/> </a>  
               </div>
            </div>
        </div>
          
           <div className=' pb-4 sm:flex xl:justify-center'>
           <div>
           <h1 className=' text-[#BAB3C3] mt-7 lg:text-center text-3xl font-medium mb-4 leading-10'>Dcentralizm</h1>
           <p className=' text-[#7E7986] font-normal lg:text-center text-[16px]   '>A multimedia art style that connects the "I" to the "We" and calls on its audience to imagine each human perspective as a jig-saw puzzle piece of the collective experience.</p>
           </div>
          
           </div>
            <div className=' wow animate__animated animate__zoomInUp flex pt-3  justify-center'>
            <div className=' hidden sm:flex gap-4'>
            <a href='https://linktr.ee/dcentralizm' target='_blank' rel='noreferrer'><img src={Icoon1} alt='Icon'/> </a>  
              <a href='#contact'> <img src={Iconn2} alt='Icon'/></a>
               <a href='https://medium.com/@aithelight/dcentralizm-divining-unity-38a4a2a9627c?source=friends_link&sk=70dda116cf96e73efd993ee7344d9bf9' target='_blank' rel='noreferrer'> <img src={Iconn3} alt='Icon'/> </a>  
              
            </div>
                
            </div>
            </div>
            </div>
           
        </div>
        </div>
    </div>
    <div className=' sm:grid sm:grid-cols-2  sm:gap-4 sm:pt-10 md:mx-5 sm:overflow-y-auto'>
    <div className='hidden sm:block'>

    </div>
      <div className= 'sm:mr-10 pt-5 sm:pt-10 sm:z-30' >
        <Latest/>
        <Public/>
        <Content/>
        <Contactus/>
        <div className=' flex justify-end'>
        <p className=' text-[#7E7986]'>© Ai dezign</p>
        </div>
       
      </div>
        
    </div>
</div>
  )
}

export default Main