import React, {useEffect, useRef, useState} from 'react'
import AddsButtion from '../SelectValue/AddButtion'
import Textarea from '../SelectValue/Textarea'
import TextBox from '../SelectValue/TextBox'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import Likee from '../../images/like.svg'
import Eye from '../../images/eye.png'
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../SelectValue/Loadingdropdown';
import Like from '../../images/appreciate.svg'
import Like2 from '../../images/appreciate2.svg'
import { db } from '../../firebase-config';
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';

function Contactusss() {
    const form = useRef();
    const [open, setOpen] = useState(false)
    const [likess, setLike] = useState(0)
    const [views, setViews] = useState(0)
    const [liked, setLiked] = useState(false)
    const  docRef = doc(db, 'likes', 'GCZUnqSsRaLievViAZag')
    const docViews = doc(db, 'likes','cRODsqChmRAH1pAju9FK')
    onSnapshot((docRef), (doc) =>{
      setLike(doc.data().likes)
    })
    onSnapshot((docViews), (doc) =>{
      setViews(doc.data().views)
    })
  
    const getView = async ()=>{
      let viewDoc = await getDoc(docViews);
      if (viewDoc.exists()) {
        console.log("Document data:", viewDoc.data());
        setViews(viewDoc.get("views"))
        updateDoc(docViews,{
          views:  viewDoc.get("views") + 1
        }).then(()=>{
          
        })
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    const like = () =>{
      const  docRef = doc(db, 'likes', 'GCZUnqSsRaLievViAZag')
      if(liked){
       
        updateDoc(docRef,{
          likes:  likess- 1
        }).then(()=>{
          setLiked(false)
        })
      }else{
        updateDoc(docRef,{
          likes:  likess+ 1
        }).then(()=>{
          setLiked(true)
        })
      }
     
     

    }
    
  const sendEmail = (e) => {
    e.preventDefault();
    setOpen(true)
    emailjs.sendForm('service_y5b81qc', 'template_gny8fhi', form.current, 'TADsQHIzOyPE8sq_t')
      .then((result) => {
        setOpen(false)
          console.log(result.text);
          toast("Message sent sucessfully")
          e.target.reset()
      }, (error) => {
        setOpen(false)
          console.log(error.text);
      });
  };
  onSnapshot (  (docViews), (doc)  =>{
    setViews(doc.data().views)
       
     })

     useEffect (() =>{
      getView();
    }, [])
  return (
    <>
        <Loading
        open={open}
    />
     <div className=' md:mx-10 py-[96px] md:py-[120px] px-5 lg:mx-20 grid sm:grid-cols-12  gap-5' id='contact'>

<div className=' sm:col-span-4 mb-5 sm:mb-0'>
    <img src={!liked ? Like : Like2} onClick={like} className=' sm:mx-auto' alt='Like'/>
    <div className=' flex sm:justify-center gap-4 pt-4 '>
                <div className=' flex gap-1'>
                    <img src={Likee} alt='Like icon'/> <span className=' text-[14px] font-[500] text-[#535265]'>{likess}</span>
                </div>
                <div className=' flex gap-1'>
                <div className=' pt-1'>
                <img src={Eye} alt='Like icon'/>
                </div>
                  <span className=' text-[14px] font-[500] text-[#535265]'>{views}</span>   
                </div>
            </div>
</div>
<div className=' sm:col-span-6'>
<div className=' w-full'>
<h1 className=' text-[23px] sm:text-[26px] md:text-[32px] font-[600] text-header-text mb-3'>Let’s connect</h1>
<ToastContainer />
    <form ref={form} onSubmit={sendEmail}>
       
       <div className=' my-3'>
       <TextBox
           place='Your name'
           type='text'
           name="user_name"
           color=' bg-[#EFF0F2]'
       />
       </div>
       <div className=' my-3'>
       <TextBox
           place='Your email'
           type='email'
           name="user_email"
           color=' bg-[#EFF0F2]'
       />
       </div>
       <div className=' my-3'>
       <TextBox
           place='Your subject'
           type='text'
           name='email_subject'
           color=' bg-[#EFF0F2]'
       />
       </div>
       <div className=' min-w-fit relative my-5'>
           <Textarea 
               place='Your message'
               name='message'
               color=' bg-[#EFF0F2]'
           />
           <div className=' absolute bottom-3 right-2 '>
           <AddsButtion
               name='Send'
           />
       </div>
       </div>
       
   </form>
    </div>
</div>
</div>
    </>
   
  )
}

export default Contactusss